import ShowErrorNotification from "Components/UI/Notif"
import Spinner from "Components/UI/Spinner"
import { useState, useEffect } from "react"
import { createPortalSession } from "utils/subscription"

export default function AdminBilling() {

    const [loading, setLoading] = useState(true)

    useEffect(() => {
        createPortalSession().then((url) => {
            window.location.href = url
        }).catch(err => {
            ShowErrorNotification("Une erreur est survenue. Veuillez recharger la page.")
        })
    }, [])


    return (
        <div style={{ padding: "35px 80px" }}>
            <h1>Facturation </h1>
            < div style={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", marginTop: 200 }}>
                <Spinner />
                < h3 style={{ marginTop: 30 }}> Chargement </h3>
            </div>
        </div>
    )
}