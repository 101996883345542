import { auth } from "firebaseConfig";
import { useEffect, useState, createContext, FC } from "react";
import LoadingScreen from "Screens/Loading";
import { onAuthStateChanged, User } from "firebase/auth";
import * as Sentry from "@sentry/browser";
import { analystic_setUser } from "utils/analystic";
import { UserContextState } from "types/authType.type";
import { Team } from "types/team.type";
import { getTeamForUser } from "utils/database";

const contextDefaultValues: UserContextState = {
  user: null,
  team: null
}

export const AuthContext = createContext<UserContextState>(
  contextDefaultValues
);

export const AuthProvider: FC<React.ReactNode> = ({ children }) => {
  const [_user, _setUser] = useState<User | null>(null);
  const [_isClient, _setIsClient] = useState<boolean>(false)
  const [_team, _setTeam] = useState<Team | null>(null)

  const [pending, setPending] = useState(true);

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      _setUser(user)
      setPending(false)
      try { Sentry.setUser({ email: user!.uid }) } catch (err) { }
      try { analystic_setUser(user!.uid) } catch (err) { }
    });
    return () => unsubscribe();
  }, []);


  const getTeam = async (): Promise<Team> => {
    try {
      if (_team) return _team
      const team = await getTeamForUser(_user!.uid)
      _setTeam(team)
      return team
    } catch (err) {
      throw err
    }
  }

  if (pending) {
    return (
      <div
        style={{
          height: "100vh",
        }}
      >
        <LoadingScreen />
      </div>
    );
  }

  return (
    <AuthContext.Provider value={{ user: _user, team: _team, getTeam }} >
      {children}
    </AuthContext.Provider>
  );
};
