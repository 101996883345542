import { FC, useContext, useState } from "react";
import styles from "./index.module.css";
import { Button, message, Dropdown, Menu } from "antd";
import { AuthContext } from "Providers/AuthProvider";
import downloadReport from "utils/reportGenerator";
import { analystic_logEvent } from "utils/analystic";
import { ClockCircleOutlined, WarningOutlined, CheckOutlined, MailOutlined, DownloadOutlined, CaretDownOutlined, DeleteOutlined } from "@ant-design/icons"
import { deleteLink, pdfErrorRequest, setMailResend } from "utils/database";
import { getFormalDate } from "utils/dateHelper";
import { DashboardLink } from "types/dashboard.type";

const columns = ["", "Destinataire", "Statut", "Dernière modification", ""];

const InboxTable: FC<{ links: DashboardLink[] }> = ({ links }) => {
  const { user } = useContext(AuthContext);

  return (
    <>
      <table className={styles.table}>
        <thead className={styles.thead}>
          <tr className={styles.tr}>
            <th
              className={styles.th}
              style={{
                minWidth: 60,
                maxWidth: 60,
                padding: "0px 10px 0px 24px",
              }}
            ></th>
            <th className={styles.th} style={{ width: 250, padding: 0 }}>
              <p style={{ margin: 0, marginLeft: 0, width: 250 }}>{columns[1]}</p>
            </th>
            <th className={styles.th} style={{ minWidth: 110, maxWidth: 110, paddingLeft: 0 }}>
              {columns[2]}
            </th>
            <th className={styles.th} style={{ minWidth: 90, paddingLeft: 0, maxWidth: 90 }}>
              <p style={{ marginLeft: 0, maxWidth: 90 }}>{columns[3]}</p>
            </th>
            <th className={styles.th} style={{ minWidth: 90 }}>
              <p style={{ marginLeft: 35, maxWidth: 250 }}>{columns[4]}</p>
            </th>
          </tr>
        </thead>

        <tbody className={styles.tbody}>
          {links.map((link, index) => (
            <tr className={styles.tr} key={index}>
              <TimeRow link={link} />
              <DestinataireRow link={link} />
              <StatusRow link={link} />
              <DateRow link={link} />
              <ButtonRow link={link} userID={user!.uid} />
            </tr>
          ))}
        </tbody>
      </table>
    </>
  );
}

const TimeRow: FC<{ link: DashboardLink }> = ({ link }) => {
  return (
    <td className={styles.td} style={{ width: 60, boxSizing: "border-box" }}>
      <div style={{ width: 65, display: "flex", justifyContent: "center" }}>
        {link.status == "set" && <CheckOutlined style={{ color: "#19DB7E" }} />}
        {link.status == "expired" && <WarningOutlined style={{ color: "#F31830" }} />}
        {link.status == "waiting" && <ClockCircleOutlined style={{ color: "#EC7E00" }} />}
        {link.status == "resend" && <ClockCircleOutlined style={{ color: "#EC7E00" }} />}
      </div>
    </td>
  );
}

const DestinataireRow: FC<{ link: DashboardLink }> = ({ link }) => {
  return (
    <td className={styles.td} style={{ width: 60 }}>
      <p>{link.clientMail}</p>
    </td>
  );
}


const StatusRow: FC<{ link: DashboardLink }> = ({ link }) => {
  let color = "#EC7E00";
  let text = "";
  let barPourcentage = 100

  switch (link.status) {
    case "set":
      color = "#19DB7E";
      text = "Complété";
      break;
    case "expired":
      color = "#FF4D4F";
      text = "Echec d'authentification";
      break;
    case "waiting":
      color = "#EC7E00";
      text = "En attente";
      barPourcentage = 50
      break;
    case "resend":
      color = "#EC7E00";
      text = "Le destinataire a ouvert le lien mais n'a pas envoyé son RIB.";
      barPourcentage = 50
      break;
    default:
      color = "#EC7E00";
      text = "";
      break;
  }

  return (
    <td className={styles.td} style={{ width: 130, paddingRight: 30, paddingTop: 15, paddingBottom: 15 }}>
      <div style={{ width: 100, height: 7, background: "#E9E9E9", marginBottom: 10 }}>
        <div
          style={{
            width: barPourcentage,
            background: color,
            height: 7,
          }}
        />
      </div>
      <p style={{ color: "#1E1E1E", fontSize: 12, marginRight: 15, marginTop: 5 }}>{text}</p>
    </td>
  );
}

const DateRow: FC<{ link: DashboardLink }> = ({ link }) => {
  return (
    <td className={styles.td} style={{ width: 60 }}>
      <span style={{ "textAlign": "center", width: 60 }}>{getFormalDate(new Date(link.lastUpdate.seconds * 1000))}</span>
    </td>
  );
}


const ButtonRow: FC<{ link: DashboardLink, userID: string }> = ({ link, userID }) => {
  const [loading, setLoading] = useState(false);
  const [dialogOpen, setDialogOpen] = useState(false);

  const handleDownload = async () => {
    setLoading(true)
    try {
      await downloadReport(link)
      message.success("Le fichier a été téléchargé avec succès. Veuillez vérifier votre dossier « Téléchargement ».", 10)
    } catch (err) {
      console.log("err: ", err)
      await showDownloadFailed()
      analystic_logEvent("request_download_report", { linkID: link.id })
    } finally {
      setLoading(false)
      return
    }
  };

  const handleResendMail = () => {
    message.success("Le lien sécurisé a été copié dans le presse-papiers. Collez-le dans un courrier au client.", 10)
    setMailResend(link.id).catch(err => { console.log("err") })
  }

  async function showDownloadFailed() {
    setDialogOpen(true);
    await pdfErrorRequest(link.id, userID)
  }

  const handleDelete = () => {
    deleteLink(link.id).then(() => {
      message.info("Le lien a été supprimé avec succès.", 1.3)
    }).catch((err) => {
      message.error("Une erreur s'est produite. Impossible de supprimer. Contactez l'assistance si le problème persiste")
    })
  }


  function handleMenuClick() {
    handleDelete()
  }

  const handleBtnPressed = () => {
    const status = link.status
    if (status == "resend") handleResendMail()
    if (status == "expired") handleDelete()
    if (status == "set") handleDownload()
  }

  const menu = (
    <Menu onClick={handleMenuClick}>
      <Menu.Item key="1">Supprimer</Menu.Item>
    </Menu>
  );


  const getIconForStatus = () => {
    switch (link.status) {
      case "set":
        return <DownloadOutlined style={{ color: "white" }} />
      case "resend":
        return <MailOutlined style={{ color: "#EC7E00" }} />
      case "expired":
        return <DeleteOutlined style={{ color: "white" }} />
      default:
        return null
    }
  }

  const getButtonStyleForStatus = () => {
    switch (link.status) {
      case "set":
        return { background: "#2463D1" }
      case "resend":
        return { background: "#F9F9F9", color: "#EC7E00", border: "1px solid #EC7E00" }
      case "expired":
        return { background: "red", color: "white" }
      default:
        return {}
    }
  }

  const getButtonTextForStatus = () => {
    switch (link.status) {
      case "set":
        return "Télécharger"
      case "resend":
        return "Renvoyer"
      case "expired":
        return "Supprimer"
      case "waiting":
        return "En attente"
      default:
        return ""
    }
  }

  return (
    <td className={styles.td} style={{ width: 180, minWidth: 180 }}>
      <div style={{ width: "100%", display: "flex", justifyContent: "center" }}>
        <Button
          onClick={handleBtnPressed}
          loading={loading}
          disabled={link.status == "waiting"}
          style={{ borderRadius: 0, minWidth: 130, ...getButtonStyleForStatus() }}
          type={link.status == "resend" ? "default" : "primary"}
          icon={getIconForStatus()} size={"middle"}>
          <>{getButtonTextForStatus()} </>
        </Button>
        <Dropdown overlay={menu}>
          <Button style={{ padding: "0px 6px", borderLeftWidth: 0 }}>
            <CaretDownOutlined />
          </Button>
        </Dropdown>
      </div>
    </td>
  )

}

export default InboxTable;
