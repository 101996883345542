import { useEffect, useState } from "react";
import isElectron from 'is-electron';
import { useNavigate } from "react-router-dom";
import { downloadSoftware } from "utils/storage";
import { Button } from "antd";
import { DOWNLOAD_TUTORIAL_LINK } from "utils/dev";

export default function SoftwareDownload() {

    const navigation = useNavigate();

    const [loading, setLoading] = useState(false)

    useEffect(() => {
        try {
            if (isElectron()) {
                navigation("/")
            }
        } catch (err) {
            navigation("/")
        }
    }, [])

    const softwareDownload = async () => {
        setLoading(true)
        await downloadSoftware()
        setLoading(false)
        window.location.href = DOWNLOAD_TUTORIAL_LINK;
    }

    return (
        <div style={{ height: "100vh", display: "flex", flexDirection: "column", padding: "0px 70px", marginTop: 150, alignItems: "center" }}>
            <div style={{ maxWidth: 650, display: "flex", flexDirection: "column", alignItems: "center" }}>
                <h1
                    style={{
                        fontSize: 16,
                        fontWeight: "700",
                        textAlign: "center"
                    }}
                >
                    APPLICATION POUR ORDINATEUR
                </h1>
                <h4
                    style={{
                        fontSize: 28,
                        fontWeight: "500",
                        textAlign: "center"
                    }}
                >
                    Safety Check pour Windows
                </h4>
                <p
                    style={{
                        fontSize: 21,
                        textAlign: "center"
                    }}
                >
                    L'application de bureau native de Safety Check vous permet de vous concentré sur l'essentiel. Téléchargez simplement la version qui corespond a votre système d'exploitation, connectez-vous avec notre numero de telephone portable.
                </p>
                <Button loading={loading} onClick={softwareDownload} style={{ backgroundColor: '#0666EB', borderColor: "#0666EB" }} type="primary" shape="round" size={"large"}>
                    Télécharger
                </Button>
                <a href={"/"} style={{ marginTop: 15 }}>Plus tard</a>
            </div>
        </div>
    );
}
