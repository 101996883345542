import { Button } from "antd";
import React, { useState, useEffect, FC } from "react";
//@ts-ignore
import fr from "react-phone-number-input/locale/fr.json"
import { ArrowLeftOutlined } from '@ant-design/icons';
import "./index.css";
import PhoneInput, { isValidPhoneNumber } from "react-phone-number-input";
import { analystic_logScreen } from "utils/analystic";
import ShowErrorNotification from "Components/UI/Notif";
import { useNavigate } from "react-router-dom";


interface NewLinkPhoneProps {
  handlePhoneNumber: (phone: string) => void,
  phoneNumber: string

}

const NewLinkPhone: FC<NewLinkPhoneProps> = (props) => {
  const navigation = useNavigate();

  const [phoneNumber, setPhoneNumber] = useState<string>(props.phoneNumber);
  const [loading, setLoading] = useState(false);

  const delay = (ms: number) => new Promise((res) => setTimeout(res, ms));

  useEffect(() => {
    analystic_logScreen("new_link_phone_page")
  }, []);

  const handleSubmit = async (e: React.SyntheticEvent) => {
    e.preventDefault();

    if (isValidPhoneNumber(phoneNumber) && (isMobilePhoneNumber())) {
      setLoading(true);
      await delay(900);
      setLoading(false);

      props.handlePhoneNumber(phoneNumber);
    } else {
      ShowErrorNotification("Le numéro de téléphone doit être un numero de téléphone mobile valide");
    }
  };

  const isMobilePhoneNumber = (): boolean => {
    //check if string begin with
    if (phoneNumber.startsWith("+33")) {
      return ((phoneNumber.startsWith("+336")) || (phoneNumber.startsWith("+337")));
    } else {
      return true;
    }
  }

  return (
    <div
      style={{
        flex: 1,
        display: "flex",
        alignItems: "center",
        flexDirection: "column",
        height: "100vh",
      }}
    >
      <form
        onSubmit={handleSubmit}
        style={{
          marginTop: "10%",
          flex: 1,
          justifyContent: "space-between",
          padding: 13,
          display: "flex",
          flexDirection: "column",
          width: "100%",
          maxWidth: 555,
          boxSizing: "border-box",
        }}
      >
        <div>
          <div
            style={{
              marginTop: 30,
              display: "flex",
              alignItems: "center",
              justifyContent: "flex-start",
              width: "100%",
            }}
          >
            <ArrowLeftOutlined onClick={() => navigation("/home")} style={{ cursor: "pointer", marginRight: 5 }} />
            <h1
              style={{
                fontSize: 27,
                fontWeight: "normal",
                textAlign: "left",
                color: "#000",
                margin: 0,
                marginLeft: 10
              }}
            >
              Ajouter le numéro de téléphone mobile.
            </h1>
          </div>
          <h5
            style={{
              textAlign: "left",
              color: "#525C66",
              fontSize: 16,
              marginTop: 20,
              fontWeight: "normal",
            }}
          >
            Indiquez le numéro de téléphone mobile utilisé par le destinataire. Le code secret de certification y sera envoyé.
          </h5>

          <PhoneInput
            autoFocus
            placeholder={"Numéro de portable"}
            style={{ marginTop: 70 }}
            labels={fr} defaultCountry="FR"
            value={phoneNumber}
            onChange={(v) => setPhoneNumber(v as string)} />
        </div>
        <Button
          onClick={handleSubmit}
          loading={loading}
          type="default"
          disabled={phoneNumber == ""}
          style={{
            marginBottom: 100,
            width: "100%",
            background: phoneNumber ? "#0666EB" : "#5C9FFB",
            maxWidth: 375,
            padding: "16px 0px",
            borderRadius: 16,
            color: "white",
            alignSelf: "center",
            height: "auto"
          }}
        >
          Suivant
        </Button>
      </form>
    </div>
  );
}

export default NewLinkPhone;
