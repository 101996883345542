import logo from "Assets/logo.png";
import { Button, Layout, Menu, Avatar, Popover, Modal } from 'antd';
import { UserOutlined, QuestionCircleOutlined, DownloadOutlined } from '@ant-design/icons';
import { logout } from "utils/auth";
import { useNavigate } from "react-router-dom";
import { FC, useContext, useEffect, useState } from "react";
import { AuthContext } from "Providers/AuthProvider";
import { daysBetween } from "utils/dateHelper";
import isElectron from "is-electron";
import { SOFTWARE_DOWNLOAD_URL } from "utils/constants";
import { downloadSoftware } from "utils/storage";
import { DOWNLOAD_TUTORIAL_LINK } from "utils/dev";
const { Header } = Layout;


interface NavbarProps {
  selected: "home" | "settings"
}

const NavBar: FC<NavbarProps> = (props) => {

  const navigation = useNavigate();
  const { getTeam } = useContext(AuthContext)

  const [freeTrialDays, setFreeTrialDays] = useState<number | null>(null)
  const [downloadLoading, setDownloadLoading] = useState(false)

  useEffect(() => {
    /* fetchTrial() */
  }, [])

  const fetchTrial = async () => {
    try {
      const team = await getTeam!()
      if ((team.status == "trial") && (team.endDate)) {
        const diff = daysBetween(new Date(team.endDate * 1000), new Date())
        setFreeTrialDays(diff)
      }
    } catch (err) {
      return
    }
  }


  const softwareDownload = async () => {
    setDownloadLoading(true)
    await downloadSoftware()
    setDownloadLoading(false)
    window.location.href = DOWNLOAD_TUTORIAL_LINK;
  }

  const profilContent = (
    <div>
      <h4> Profil </h4>
      <Button onClick={logout}>Déconnexion</Button>
    </div>
  );

  return (
    <Header style={{ backgroundColor: "#fff", position: 'fixed', zIndex: 1, width: '100%', boxShadow: "rgb(225, 228, 232) 0px -1px 0px 0px inset", display: "flex", justifyContent: "space-between" }}>
      <Menu mode="horizontal" defaultSelectedKeys={[props.selected]} style={{ flex: 1 }}>
        <Menu.Item key="0" onClick={() => navigation("/")}>
          <img src={logo} style={{ height: 20 }} />
        </Menu.Item>
        <Menu.Item style={{ fontWeight: "500" }} onClick={() => navigation("/")} key="home" >Accueil</Menu.Item>
        <Menu.Item style={{ fontWeight: "500" }} key="2" > <a target={"_blank"} href="https://tawk.to/chat/6149db23d326717cb6829699/1fg4801an"> Contacter l'assistance </a></Menu.Item>
        <Menu.Item style={{ fontWeight: "500" }} key="setting" onClick={() => navigation("/settings")}>Paramètres</Menu.Item>
      </Menu>

      <div style={{ flex: 1, display: "flex", justifyContent: "flex-end", alignItems: "center" }}>

        {
          (!isElectron()) &&
          <Button icon={<DownloadOutlined />} loading={downloadLoading} onClick={softwareDownload} style={{ marginRight: 30, backgroundColor: '#0666EB', borderColor: "#0666EB" }} type="primary" shape="round" size={"large"}>
            Télécharger le logiciel
          </Button>
        }
        {
          (freeTrialDays !== null) && <div>
            <span style={{ color: "#1373F5", fontWeight: "bolder", marginRight: 15 }}>
              {freeTrialDays} jours restants
            </span>
            <Button onClick={() => navigation("/settings/billing")} style={{ marginRight: 30, backgroundColor: '#0666EB', borderColor: "#0666EB" }} type="primary" shape="round" size={"large"}>
              Voir les forfaits
            </Button>
          </div>
        }
        <a target={"_blank"} href={"https://glory-stay-81a.notion.site/Mode-d-emploi-f3c5b1ca0377415da28c6bed432e1c96"}>
          <QuestionCircleOutlined style={{ marginRight: 40, fontSize: 20, color: "#E5E5E5" }} />
        </a>

        <Popover placement="bottom" content={profilContent} trigger="click">
          <Avatar size={35} icon={<UserOutlined />} style={{ cursor: "pointer" }} />
        </Popover>
      </div>
    </Header>
  )
}

export default NavBar;
