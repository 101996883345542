import { useContext, useState } from "react"
import { AuthContext } from "Providers/AuthProvider"
import { addMultipleTeamMembers } from "utils/database"
import ShowErrorNotification from "Components/UI/Notif";
import { notification, Select, Button, Modal } from "antd";
import styles from "./index.module.css"
import { TeamOutlined } from "@ant-design/icons";
import { SUBSCRIPTION_SEATS_MINIMUM } from "utils/subscription";
import MembersTable from "Components/Admin/Settings/Team/Table";

export default function Settings() {
    const { getTeam } = useContext(AuthContext)
    const [addedUserMails, setAddUserMail] = useState([])

    const [tempUserEmails, setTempUserEmails] = useState([])

    const [isModalOpen, setModalOpen] = useState(false)
    const [loading, setLoading] = useState(false)

    const handleAddUser = async () => {
        if (addedUserMails.length == 0) { return }
        try {
            setTempUserEmails(addedUserMails)
            setLoading(true)
            const team = await getTeam!()
            await addMultipleTeamMembers(addedUserMails, team.teamID)
            setModalOpen(true)
            notification["success"]({
                message: 'Succès',
                description:
                    'Les utilisateurs ont été ajoutés avec succès',
            });
            setLoading(false)
        } catch (err) {
            ShowErrorNotification("")
            setLoading(false)
        }
    }

    return (
        <div style={{ padding: "35px 80px" }}>

            <div className={styles.headerContainer}>
                <div className={styles.headerIconContainer}> <TeamOutlined style={{ color: "white" }} /> </div>
                <h3> Membres de l'équipe </h3>
            </div>
            <h3 className={styles.subtitle}>Invitez les membres de votre équipe</h3>
            {/* <span style={{ color: "#565656" }}>
                Votre abonement actuel comprend {SUBSCRIPTION_SEATS_MINIMUM} membres actifs gratuit. Des frais seront appliquer si vous ajoutez plus de membres.
            </span> */}
            <div style={{ display: "flex", marginTop: 20 }}>
                <Select notFoundContent={""} mode="tags" style={{ width: '100%' }} value={addedUserMails} placeholder={"Saisir les e-mails, séparés par un espace"} size={"large"} onChange={(e) => setAddUserMail(e)} tokenSeparators={[' ']} />
                <Button loading={loading} onClick={handleAddUser} size={"large"} type="primary">Inviter</Button>
            </div>
            <MembersTable />
            <Modal
                onCancel={() => setModalOpen(false)}
                title="Rappel"
                visible={isModalOpen}
                footer={[
                    <div>
                        <Button onClick={() => setModalOpen(false)}>
                            Fermer
                        </Button>
                        <Button type="primary">
                            <a href={`mailto:${tempUserEmails.join(",")}?subject=Authentification%20RIB%20par%20SafetyCheck&body=Merci%20d%E2%80%99utiliser%20SafetyCheck%20afin%20d%E2%80%99authentifier%20les%20RIB%20clients.%0ASi%20l%E2%80%99invitation%20SafetyCheck%20ne%20figure%20pas%20dans%20votre%20bo%C3%AEte%20de%20r%C3%A9ception%2C%20peut%20%C3%AAtre%20est%20elle%20en%20%C2%AB%20ind%C3%A9sirables%20%C2%BB%0AJe%20vous%20laisse%20v%C3%A9rifier.`}>Avertir les collaborateurs </a>
                        </Button>
                    </div>
                ]}
            >
                <p>Il peut arriver que les courriels adressés à vos collaborateurs soient malencontreusement classés en indésirables.</p>
                <p>Merci de les en informer.</p>
            </Modal>
        </div>
    )
}
