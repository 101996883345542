import { AuthContext } from "Providers/AuthProvider";
import { useContext, useEffect, useRef, useState } from "react";
import UserAuthPhoneCode from "../PhoneCode";
import UserPhoneAuthNumber from "../PhoneNumber";
import { auth } from "firebaseConfig";
import { ConfirmationResult, RecaptchaVerifier, signInWithPhoneNumber } from "firebase/auth";
import ShowErrorNotification from "Components/UI/Notif";
import { useNavigate } from "react-router-dom";


export default function UserAuthController() {
  const navigation = useNavigate();
  const { user } = useContext(AuthContext);

  const [phoneNumber, setPhoneNumber] = useState<string | null>(null)

  const [index, setIndex] = useState(0)
  const [loading, setLoading] = useState(false)

  const [recaptcha, setRecaptcha] = useState<RecaptchaVerifier | null>(null);
  const element = useRef(null);

  const [confirmationResult, setConfirmationResult] = useState<ConfirmationResult | null>(null);


  useEffect(() => {
    if (user) {
      navigation("/home");
      return;
    }
    if (!recaptcha) {
      const verifier = new RecaptchaVerifier(element.current!, { size: "invisible" }, auth);
      verifier.verify().then(() => setRecaptcha(verifier));
      return
    }
  }, []);

  const next = () => setIndex(index + 1)

  const handlePhoneNumber = (phone: string) => {
    setPhoneNumber(phone)
    sendCode(phone)
    next()
  }

  const sendCode = async (phone = phoneNumber) => {
    try {
      const confirmation = await signInWithPhoneNumber(auth, phone!, recaptcha!)
      setConfirmationResult(confirmation);
      setLoading(false)
    } catch (err) {
      ShowErrorNotification("Le numéro de téléphone ne semble pas valide.")
      setLoading(false)
    }
  }

  const checkCode = async (code: string) => {
    setLoading(true)
    try {
      const result = await confirmationResult!.confirm(code);
      if (!result.user) {
        throw new Error("error")
      }
      navigation("/home");
    } catch (err) {
      ShowErrorNotification("Le code est incorrect")
      setLoading(false)
    }
  }

  return (
    <div>
      {index == 0 && <UserPhoneAuthNumber handlePhoneNumber={handlePhoneNumber} />}
      {index == 1 && <UserAuthPhoneCode
        phoneNumber={phoneNumber!}
        loading={loading}
        checkCode={checkCode}
        handleResend={() => sendCode(phoneNumber)}
      />}
      <div ref={element}></div>
    </div>
  );
}
