import { Button } from "antd";
import { FC, useState } from "react";
import PhoneInput, { isValidPhoneNumber } from "react-phone-number-input";
//@ts-ignore
import fr from "react-phone-number-input/locale/fr.json";
import ShowErrorNotification from "Components/UI/Notif";
import { checkUserExist } from "utils/server";

const UserPhoneAuthNumber: FC<{ handlePhoneNumber: (phone: string) => void }> = (props) => {
  const [phoneNumber, setPhoneNumber] = useState<string>("");
  const [loading, setLoading] = useState(false);

  const handleSubmit = async (e: React.SyntheticEvent) => {
    e.preventDefault();
    setLoading(true);

    if (!isValidPhoneNumber(phoneNumber)) {
      ShowErrorNotification("Le numéro de téléphone est incorrect")
      setLoading(false);
      return;
    }

    const phone = phoneNumber;
    checkUserExist(phone).then((exist) => {
      if (exist) {
        props.handlePhoneNumber(phone)
        return
      }
      else {
        setLoading(false);
        ShowErrorNotification("Se compte n'existe pas. Inscrivez vous!")
      }
    }).catch(err => {
      setLoading(false);
      ShowErrorNotification("Reesayer plus tard ou contactez l'assitance")
    })
  };

  return (
    <div
      style={{
        flex: 1,
        display: "flex",
        alignItems: "center",
        flexDirection: "column",
        height: "100vh",
        padding: 20,
      }}
    >
      <form
        onSubmit={handleSubmit}
        style={{
          marginTop: 120,
          flex: 1,
          justifyContent: "space-between",
          padding: 13,
          display: "flex",
          flexDirection: "column",
          width: "100%",
          maxWidth: 535,
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
          }}
        >
          <h1
            style={{
              fontSize: 33,
              color: "#000",
            }}
          >
            Se connecter à Safety Check
          </h1>
          <h1
            style={{
              fontSize: 17,
              color: "#525c66",
              fontWeight: "normal",
            }}
          >
            Pour vous connecter, indiquez le numéro de téléphone portable utilisé lors de votre inscription.
          </h1>
          <span>Vous n'avez pas de compte ? <a href={"/signup"}> Inscrivez-vous </a> </span>

          <PhoneInput style={{ marginTop: 70 }} labels={fr} defaultCountry="FR" value={phoneNumber} onChange={(v) => setPhoneNumber(v as string)} />
        </div>
        <Button
          loading={loading}
          disabled={loading}
          htmlType="submit"
          style={{
            width: "100%",
            background: phoneNumber ? "#0666EB" : "#5C9FFB",
            maxWidth: 375,
            borderRadius: 16,
            color: "white",
            alignSelf: "center",
            position: "absolute",
            bottom: 40,
            left: "50%",
            transform: "translateX(-50%)",
            height: 60
          }}
        >
          Se connecter
        </Button>
      </form>
    </div>
  );
}

export default UserPhoneAuthNumber;
