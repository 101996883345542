export default function DownloadScreen() {

  return (
    <div style={{ height: "100vh", display: "flex", justifyContent: "center", flexDirection: "column", padding: "0px 70px", background: "#222B37" }}>
      <div style={{ maxWidth: 650 }}>
        <h1
          style={{
            fontSize: 16,
            fontWeight: "700",
            color: "#fff",
          }}
        >
          APPLICATION POUR ORDINATEUR
        </h1>
        <h4
          style={{
            fontSize: 28,
            fontWeight: "500",
            color: "#fff",
          }}
        >
          Safety Check pour Windows
        </h4>
        <p
          style={{
            fontSize: 21,
            color: "#fff",
          }}
        >
          L'application de bureau native de Safety Check vous permet de vous concentré sur l'essentiel. Téléchargez simplement la version qui corespond a votre système d'exploitation, connectez-vous avec notre numero de telephone portable.
        </p>

        <a style={{ color: "black", background: "white", borderRadius: 7, padding: "10px 19px" , fontSize: 20}} href="https://firebasestorage.googleapis.com/v0/b/sentinel-288bb.appspot.com/o/App%2FSafety%20Check%20installateur.exe?alt=media&token=ef7ef4a5-ffef-4c4d-b2e7-7d67f9f7232d" download="proposed_file_name">
          Télécharger
        </a>

      </div>
    </div>
  );
}
