import { httpsCallable } from "firebase/functions"
import { cloudFunction } from "firebaseConfig"

export const APIURL = window.location.hostname === 'localhost' ? 'http://localhost:8090' : ''

export const checkUserExist = async (phoneNumber: string): Promise<boolean> => {
    try {
        const userExist = httpsCallable(cloudFunction, "checkIsUserAdmin");
        const exist = await userExist({ phoneNumber }) as any
        return exist.data.exist as boolean
    } catch (err) {
        throw err
    }
}

export const setTeamToUser = async (): Promise<void> => {
    try {
        const setTeam = httpsCallable(cloudFunction, "setTeamToUser");
        await setTeam()
        return
    } catch (err) {
        throw err
    }
}

export const checkInvitationPending = async (email: string): Promise<string> => {
    try {
        const getInvitation = httpsCallable(cloudFunction, "checkInvitationPending");
        const response = await getInvitation({ email }) as any
        return response.data.invitationID as string
    } catch (err) {
        throw err
    }
}

export const sendEmailVerification = async (): Promise<void> => {
    try {
        const verifyEmail = httpsCallable(cloudFunction, "sendMailVerification");
        await verifyEmail()
        return
    } catch (err) {
        throw err
    }
}