import { Outlet } from "react-router-dom";
import { Layout } from "antd";
import logo from "Assets/logo.png";
const { Content, Header } = Layout;

export default function OnboardingLayout() {

    return (
        <Layout>
            <NavBar />
            <Layout>
                <Content style={{ backgroundColor: "white" }}>
                    <Outlet />
                </Content>
            </Layout >
        </Layout >
    )
}



const NavBar = () => {
    return (
        <Header style={{ backgroundColor: "#fff", position: 'fixed', zIndex: 1, width: '100%', boxShadow: "rgb(225, 228, 232) 0px -1px 0px 0px inset", display: "flex", justifyContent: "space-between" }}>
            <div style={{ flex: 1, display: "flex", justifyContent: "flex-start", alignItems: "center" }}>
                <img src={logo} style={{ height: 20 }} />
            </div>
        </Header>
    )
}



