import { MailOutlined, MobileOutlined } from "@ant-design/icons";
import { Button, Result } from "antd";
import ShowErrorNotification from "Components/UI/Notif";
import { AuthContext } from "Providers/AuthProvider";
import { useContext, useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import LoadingScreen from "Screens/Loading";
import { sendEmailVerification, setTeamToUser } from "utils/server";
import { isMobile } from 'react-device-detect';


export default function EmailVerification() {
    const navigation = useNavigate();
    const [searchParams, _] = useSearchParams();

    const [loading, setLoading] = useState(true);


    const { user } = useContext(AuthContext)

    useEffect(() => {
        if (!isMobile) {
            if (user?.emailVerified) {
                (async function runFunction() {
                    await handleEmailVerified()
                })()
                return
            }
            sendEmailVerification().catch(err => { console.log(err) })

            setLoading(false)

            if (searchParams.get("from")) {
                ShowErrorNotification("Vous n'avez pas validé votre email. Assurez-vous d'avoir cliqué sur le lien que nous vous avons envoyé.")
            }
        }
    }, [])

    const checkEmailVerification = async () => {
        window.location.reload();
    }

    const handleEmailVerified = async () => {
        try {
            await setTeamToUser()
            await checkDestination()
        } catch (err) {
            ShowErrorNotification("Contactez le support: support@getsafetycheck.fr")
        }
    }

    const checkDestination = async () => {
        navigation("/")
        return
    }

    const openMail = () => {
        //crite mailto to no adress with subject "SafetyCheck" and body "https://app.getsafetycheck.fr/login"
        window.location.href = `mailto:?subject=SafetyCheck.fr&body=https://app.getsafetycheck.fr/login`
    }

    if (isMobile) {
        return (
            <div style={{ marginTop: 100 }}>
                <div style={{ display: "flex", justifyContent: "center" }}>
                    <Result
                        style={{ maxWidth: 600 }}
                        icon={<MobileOutlined />}
                        title={"L'espace de travail Safety Check n'est pas compatible avec le petit écran des mobiles."}
                        subTitle={`Pour profiter pleinement de SafetyCheck, veuillez vous connecter à l'aide d'un ordinateur.`}
                        extra={<Button href="mailto:?subject=SafetyCheck.fr&body=https://app.getsafetycheck.fr/login" type="primary">Envoyez moi le lien par email</Button>}
                    />,
                </div>
            </div>
        )
    }

    if (loading) {
        return (
            <div
                style={{
                    height: "100vh",
                }}
            >
                <LoadingScreen />
            </div>
        );
    }

    return (
        <div style={{ marginTop: 100 }}>
            <div style={{ display: "flex", justifyContent: "center" }}>
                <Result
                    style={{ maxWidth: 600 }}
                    icon={<MailOutlined />}
                    title="Merci de valider votre email"
                    subTitle={`Nous vous avons envoyé un e-mail de confirmation à ${user?.email}. Veuillez vérifier votre dossier spam si vous ne le trouvez pas. Une fois que vous avez validé votre email, revenez sur cette page et cliquez sur le bouton "J'ai validé mon email".`}
                    extra={<Button onClick={checkEmailVerification} type="primary">J'ai validé mon email</Button>}
                />,
            </div>
        </div>
    );
}
