import { Layout, Form, Input, Button, Space } from 'antd';
import logo from "Assets/logo.png";
import PhoneAuth from 'Components/Auth/PhoneAuth';
import ShowErrorNotification from 'Components/UI/Notif';
import { useEffect, useState } from 'react';
import { getInvitationInfos, signupNewAdminUser } from 'utils/database';
import LoadingScreen from 'Screens/Loading';
import { logout } from "utils/auth"
import { useNavigate } from 'react-router-dom';
import { checkUserExist } from 'utils/server';
import { Invitation } from 'types/team.type';
import { updateEmail, User } from 'firebase/auth';

const { Header, Content } = Layout;

export default function InvitationSignup() {

    const [loading, setLoading] = useState(true)
    const [isPhoneScreen, setIsPhoneScreen] = useState(false)

    const [userInfos, setUserInfos] = useState<any>({})

    const navigation = useNavigate();

    const [invitation, setInvitation] = useState<Invitation | null>(null)

    const delay = (ms: number) => new Promise((res) => setTimeout(res, ms));

    useEffect(() => {
        (async function setup() {
            try {
                logout()
                const queryParams = new URLSearchParams(window.location.search);
                const invitationID = queryParams.get('id') as string;

                const invitationData = await getInvitationInfos(invitationID)
                setInvitation(invitationData)
                setLoading(false)
            } catch (err) {
                navigation("/notfound")
            }
        })();
    }, [])

    const onFinish = async (values: any) => {

        if (values.email.toLowerCase() !== invitation!.email) {
            ShowErrorNotification("L'email ne correspond pas a l'email d'invitation. Contactez votre Administrateur")
            return
        }

        setLoading(true)
        await delay(2000);
        setLoading(false)
        setUserInfos(values)
        setIsPhoneScreen(true)
    };

    const onFinishFailed = (errorInfo: any) => { };

    const handlePhoneSuccess = async (user: User) => {
        try {
            const exist = await checkUserExist(user.phoneNumber!)
            if (exist) {
                navigation("/")
                return
            }
            await updateEmail(user, userInfos.email)
            await signupNewAdminUser(user.uid, userInfos.firstname, userInfos.lastname, userInfos.email, user.phoneNumber!, invitation!.teamID)
            navigation("/onboarding/email-verification")
        } catch (err) {
            ShowErrorNotification("")
        }
    }

    if (!invitation) return (<div style={{ height: "100vh" }}> <LoadingScreen /> </div>)

    return (
        <Layout>
            <Header style={{ backgroundColor: "#fff", position: 'fixed', zIndex: 1, width: '100%', boxShadow: "rgb(225, 228, 232) 0px -1px 0px 0px inset", display: "flex", justifyContent: "space-between" }}>
                <a href="https://getsafetycheck.fr">
                    <img src={logo} style={{ height: 20 }} />
                </a>
            </Header>
            <Layout>
                <Content style={{ display: "flex", minHeight: "100vh", alignItems: "center", flexDirection: "column", backgroundColor: "white" }}>

                    <h1 style={{ fontSize: 30, marginTop: 110, textAlign: "center" }}>Rejoigner l'équipe {invitation.teamName} !</h1>
                    <h5 style={{ fontSize: 15 }}>Pas de carte de crédit nécessaire</h5>

                    {isPhoneScreen ?
                        <PhoneAuth
                            INITIAL_COUNTER_VALUE={180}
                            onSuccess={handlePhoneSuccess}
                            onError={(message) => ShowErrorNotification(message)} /> :
                        <Form
                            name="basic"
                            onFinish={onFinish}
                            onFinishFailed={onFinishFailed}
                            autoComplete="off"
                            layout={"vertical"}
                            style={{ marginTop: 50 }}

                        >

                            <Space style={{ display: "flex" }}>
                                <Form.Item
                                    label="Prénom"
                                    name="firstname"
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Ce champ est requis',
                                        },
                                    ]}
                                >
                                    <Input placeholder={"Mark"} />
                                </Form.Item>

                                <Form.Item
                                    label="Nom"
                                    name="lastname"
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Ce champ est requis',
                                        },
                                    ]}
                                >
                                    <Input placeholder={"Dupont"} />
                                </Form.Item>
                            </Space>


                            <Form.Item
                                label="Email professionnel "
                                name="email"
                                rules={[{
                                    required: true,
                                    message: 'Ce champ est requis',
                                    type: "email"
                                }
                                ]}
                            >
                                <Input placeholder={"example@notaires.fr"} />
                            </Form.Item>

                            <Form.Item>
                                <Button loading={loading} type="primary" htmlType="submit" style={{ width: "100%" }}>
                                    COMMENCER
                                </Button>
                            </Form.Item>
                        </Form>

                    }
                    <span style={{ color: "#555555", fontSize: 13 }}>En cliquant sur le bouton "Commencer" ci-dessus, vous acceptez les</span>
                    <span style={{ color: "#555555", fontSize: 13 }}>
                        <a href={"https://glory-stay-81a.notion.site/CONDITIONS-GE-NE-RALES-DES-SITES-ET-SERVICES-SAFETY-CHECK-ee1f6ede55004a96a5513f5c79b176d2"}
                            target={"_blank"}
                            style={{ color: "#2463D1" }}>
                            Condition d'utilisations
                        </a>
                        {" "} et {" "}
                        <a target={"_blank"} href={"https://glory-stay-81a.notion.site/Politique-de-Confidentialite-b619e33d9b06469483c31038166af7fe"}
                            style={{ color: "#2463D1" }}>
                            Politique de confidentialité
                        </a>
                    </span>

                </Content>
            </Layout>
        </Layout>
    )
}