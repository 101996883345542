import Spinner from "Components/UI/Spinner";
import styles from "./index.module.css";

export default function LoadingScreen() {
  return (
    <div
      style={{
        display: "flex",
        flex: 1,
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "column",
        height: "100%",
      }}
    >
      <Spinner />
      <div className={styles.loading}>
        <p>Chargement...</p>
      </div>
    </div>
  );
}
