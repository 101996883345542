import { Button, Layout, Modal } from "antd";
import { db } from "firebaseConfig";
import React, { useContext, useEffect, useState } from "react";
import { collection, query, where, orderBy, onSnapshot, doc, setDoc, Unsubscribe } from "firebase/firestore";
import { AuthContext } from "Providers/AuthProvider";
import SideBar from "Components/Admin/Home/SideBar";
import InboxTable from "Components/Admin/Home/Table";
import { analystic_logScreen, analystic_setUserProperties } from "utils/analystic";
import AntLoading from "../../../Components/UI/AntLoading";
import NavBar from "Components/UI/NavBar";
import { TeamOutlined } from "@ant-design/icons"
import Search from "antd/lib/input/Search";
import { useNavigate } from "react-router-dom";
import isElectron from 'is-electron';
import { isTeamPopupViewed, setTeamPopupView, setUserUseSoftware } from "utils/database";
import { DashboardCategory, DashboardLink } from "types/dashboard.type";
import { setNotification } from "utils/notification";
import ShowErrorNotification from "Components/UI/Notif";
import { Link } from "types/link.type";
import { getLinkStatus } from "utils/link";
const { Content } = Layout;

const categories: DashboardCategory[] = [
  { id: "all", name: "Boîte de réception" },
  { id: "completed", name: "Complété" },
  { id: "waiting", name: "En attente d'authentification" },
  { id: "expire_soon", name: "Expire bientôt" },
  { id: "fail", name: "Échec de l'authentification" },
]

const Dashboard = () => {

  const { user, getTeam } = useContext(AuthContext);

  const delay = (ms: number) => new Promise((res) => setTimeout(res, ms));

  const [loading, setLoading] = useState(true);

  const [links, setLinks] = useState<DashboardLink[]>([]);

  const [filteredLinks, setFilteredLinks] = useState<DashboardLink[]>([]);
  const [selectedCatego, setSelectedCatego] = useState<DashboardCategory>(categories[0]);

  const navigation = useNavigate();

  var unsubscribe: Unsubscribe;

  useEffect(() => {
    checkMailVerified()
    getFiles();
    checkUseSoftware()
    analystic_logScreen("home_page")
    setNotification();
    //checkInactive()
    return function cleanup() {
      if (unsubscribe !== null) {
        unsubscribe()
      }
    }
  }, []);

  const checkMailVerified = () => {
    if (!user?.emailVerified) navigation("/onboarding/email-verification?from=home")
    else checkIsFirstLogin()
  }

  const getFiles = async () => {
    try {
      //Query all links contained in linksID
      const q = query(collection(db, "Links"),
        where("userID", "==", user!.uid),
        orderBy("lastUpdate", "desc"));
      unsubscribe = onSnapshot(q, (querySnapshot) => {
        const linksArray: DashboardLink[] = [];

        querySnapshot.forEach((doc) => {
          const link = doc.data() as Link
          const { clientMail, lastUpdate, linkID, clientID, userID } = link
          const status = getLinkStatus(link)
          linksArray.push({
            id: linkID,
            clientMail,
            lastUpdate,
            userID,
            clientID,
            status: status
          })
        });
        setLinks(linksArray);
        setFilteredLinks(getFilteredLinks(linksArray, selectedCatego));
        setLoading(false)
      });
    } catch (err) {
      ShowErrorNotification("Une erreur est survenue lors de la récupération des fichiers. Merci de recharger la page.")
    }
  };

  const checkIsFirstLogin = async () => {
    try {
      await delay(1000);

      if (await isTeamPopupViewed(user!.uid)) return
      Modal.confirm({
        title: "Ajouter vos collaborateurs",
        content: "Ajoutez quelques collègues réguliers à Safety check pour découvrir le fonctionnement de l'application.",
        icon: <TeamOutlined style={{ color: "#565656" }} />,
        okText: "Ajouter",
        cancelText: "Plus tard",
        onOk() { navigation("settings") },
      });

      setTeamPopupView(user!.uid)
    } catch (err) { return }
  }

  const checkUseSoftware = async () => {
    try {
      const use = isElectron();
      await setUserUseSoftware(user!.uid, use)
      analystic_setUserProperties({ plateform: use ? "software" : "web" })
    } catch (err) { return }
  }

  const checkInactive = async () => {
    try {
      const team = await getTeam!()
      if (team.status == "inactive") {
        Modal.confirm({
          title: "La Version d'essai a expiré",
          content: "Votre version d'essai a expiré. Vous pouvez continuer de gagner du temps en recueillant des rib.",
          icon: <TeamOutlined style={{ color: "#565656" }} />,
          okText: "Voir les forfaits.",
          closable: false,
          cancelText: "Fermer",
          cancelButtonProps: { disabled: true },
          onOk() { navigation("/settings/billing") },
        });
      }
    } catch (err) {
      return
    }
  }

  const handleCategoChange = (catego: DashboardCategory) => {
    setSelectedCatego(catego);
    setFilteredLinks(getFilteredLinks(links, catego));
  };

  const handleNewSendClick = () => {
    navigation("/new")
  }

  const getFilteredLinks = (links: DashboardLink[], catego: DashboardCategory) => {

    let filteredLinks = [...links]

    switch (catego.id) {
      case "all":
        return filteredLinks;

      case "completed":
        return filteredLinks.filter((x) => x.status == "set");

      case "expire_soon": //will run waiting
      case "waiting":
        return filteredLinks.filter((x) => x.status == "waiting")

      case "fail":
        return filteredLinks.filter((x) => x.status == "expired");
    }
  };

  const onSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (!e.target.value) {
      setFilteredLinks(getFilteredLinks(links, selectedCatego))
      return
    }
    setFilteredLinks(links.filter((x) => x.clientMail.toLowerCase().includes(e.target.value.toLowerCase())));
  };

  return (
    <Layout>
      <NavBar selected={"home"} />
      <Layout>
        <SideBar categories={categories} categoSelected={handleCategoChange} handleNewSendClick={handleNewSendClick} />
        <Content style={{ backgroundColor: "white" }}>
          <div style={{ flex: 1, marginLeft: 220, marginTop: 100 }}>
            <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center", padding: "0px 30px" }}>
              <h1 style={{ margin: 0 }}>{selectedCatego.name}</h1>
              <Search
                allowClear
                placeholder="Rechercher par destinataire"
                onChange={onSearch}
                style={{ width: 300 }}
              />
            </div>
            {loading ? (
              <div style={{ width: "100%", display: "flex", justifyContent: "center", marginTop: 150 }}>
                <AntLoading />
              </div>
            ) : filteredLinks.length == 0 ? (
              <div style={{ width: "100%", display: "flex", justifyContent: "center", marginTop: 150, flexDirection: "column", alignItems: "center", color: "#3D3D3D" }}>
                <h1 style={{ fontSize: 24, textAlign: "center" }}>Tout ce qui vous est envoyé, en un endroit</h1>
                <h4 style={{ marginTop: 8 }}>Vous trouverez ici les demandes que vous avez envoyées.</h4>
                <div>
                  <Button
                    onClick={handleNewSendClick}
                    style={{
                      marginTop: 15,
                      backgroundColor: "#0666eb",
                      borderRadius: 2,
                      color: "white",
                      fontWeight: "600",
                      width: 180,
                      whiteSpace: "normal",
                      height: "auto",
                      textTransform: "uppercase"
                    }}
                  >
                    GÉNÉRER UNE DEMANDE DE RIB
                  </Button>
                </div>
              </div>
            ) : (
              <InboxTable links={filteredLinks} />
            )}
          </div>
        </Content>
      </Layout>
    </Layout>
  )
}

export default Dashboard