
import { SettingOutlined } from "@ant-design/icons"
import { Button } from "antd"
import styles from "./index.module.css"

export default function SettingPreference() {
    return (
        <div style={{ padding: "35px 80px" }}>
            <div className={styles.headerContainer}>
                <div className={styles.headerIconContainer}> <SettingOutlined style={{ color: "white" }} /> </div>
                <h3>Préférences</h3>
            </div>

            <div style={{ marginTop: 50 }}>
                <div style={{ flex: 1, display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                    <p style={{ fontSize: 15 }}>Demander mes données personnelles</p>
                    <a target="_blank" href="mailto:support@getsafetycheck.fr?subject=Demande de données personelles">
                        <Button type="primary">Demander</Button>
                    </a>
                </div>
                <div style={{ flex: 1, display: "flex", justifyContent: "space-between", alignItems: "center", marginTop: 30 }}>
                    <p style={{ fontSize: 15 }}>Supprimer mon compte et mes données</p>
                    <a target="_blank" href="mailto:support@getsafetycheck.fr?subject=Supprimer mon compte">
                        <Button style={{ background: "red", color: "white" }}>Supprimer</Button>
                    </a>
                </div>
            </div>
        </div>
    )
}