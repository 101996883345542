import axios from "axios";
import { ref, listAll, getMetadata, uploadString, getDownloadURL, StorageReference } from "firebase/storage";
import { storage } from "firebaseConfig";
import fileDownload from "js-file-download";
import { DashboardLink } from "types/dashboard.type";

export const getStorageDownloadUrl = async (path: string) => {
    try {
        const fileRef = ref(storage, path);
        const url = await getDownloadURL(fileRef)
        return url
    } catch (err) {
        return null
    }
}

const getImagefromItems = async (items: StorageReference[]) => {
    var finalItem = items[0]
    try {
        for (const item of items) {
            const metadata = await getMetadata(item)
            if (metadata.contentType !== "application/pdf") { return item }
        }
        return finalItem
    } catch (err) {
        return finalItem
    }
}


export const getReportPdfUrl = async (link: DashboardLink) => {
    try {
        const signatureRef = ref(storage, `clientsFiles/${link.userID}/${link.id}/${link.clientID}/${link.clientMail}-${link.id}.pdf`);
        const url = await getDownloadURL(signatureRef)
        return url
    } catch (err) {
        return null
    }
}


export const getSignature = async (linkID: string, userID: string, clientID: string) => {
    try {
        const signatureRef = ref(storage, `clientsSignatures/${userID}/${clientID}/${linkID}/signature`);
        const url = await getDownloadURL(signatureRef)
        return url
    } catch (err) {
        return null
    }
}


export const downloadSoftware = async () => {
    try {
        const signatureRef = ref(storage, `Software/safetycheck.exe`);
        const url = await getDownloadURL(signatureRef)
        const clientFileBlob = await axios.get(url, { responseType: "blob" }) as any
        fileDownload(clientFileBlob.data, `Safety Check.exe`);
        return
    } catch (err) {
        return null
    }
}