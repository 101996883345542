import { FC, useEffect, useRef, useState } from "react";
import { auth } from "firebaseConfig";
import { ConfirmationResult, RecaptchaVerifier, signInWithPhoneNumber, User } from "firebase/auth";
import ShowErrorNotification from "Components/UI/Notif";
import { parsePhoneNumber } from "react-phone-number-input";
import { Button, Form, Input, Select } from "antd";
import { PhoneOutlined, LockOutlined } from "@ant-design/icons"
import { CountryCode } from "libphonenumber-js/types";

const { Option } = Select;


interface PhoneAuthProps {
    INITIAL_COUNTER_VALUE: number
    onSuccess: (user: User) => void
    onError: (message: string) => void
}

const PhoneAuth: FC<PhoneAuthProps> = (props, { INITIAL_COUNTER_VALUE = 30 }) => {
    const [phoneNumber, setPhoneNumber] = useState<string>("")
    const [counter, setCounter] = useState(INITIAL_COUNTER_VALUE);
    const [loading, setLoading] = useState(false)

    const [recaptcha, setRecaptcha] = useState<RecaptchaVerifier | null>(null);
    const element = useRef(null);

    const [confirmationResult, setConfirmationResult] = useState<ConfirmationResult | null>(null);

    //   const PHONE_COUNTRY_CODE = ((window.location.hostname == "localhost") || (getCurrentEnv() == "dev")) ? "FR" : "FR"

    useEffect(() => {
        if (!recaptcha) {
            const verifier = new RecaptchaVerifier(element.current!, { size: "invisible" }, auth);
            verifier.verify().then(() => setRecaptcha(verifier));
            return
        }
    }, []);

    useEffect(() => {
        if (confirmationResult) {
            counter > 0 && setTimeout(() => setCounter(counter - 1), 1000);
        }
    }, [counter, confirmationResult]);


    const handlePhoneNumber = (phone: string, countryCode: CountryCode) => {

        const parsedPhone = parsePhoneNumber(phone, countryCode)

        if ((parsedPhone) && (!parsedPhone.isValid())) {
            ShowErrorNotification("Le numéro de téléphone est incorrect")
            setLoading(false);
            return;
        }
        setLoading(true)

        console.log("parsedPhone: ", parsedPhone)

        const phoneFormatted = parsedPhone!.number as string
        setPhoneNumber(phoneFormatted)
        sendCode(phoneFormatted)
    }

    const sendCode = async (phone = phoneNumber, isResend = false) => {
        if ((counter !== INITIAL_COUNTER_VALUE) && (!isResend)) { return }
        try {
            const confirmation = await signInWithPhoneNumber(auth, phone!, recaptcha!)
            setConfirmationResult(confirmation);
            setLoading(false)
        } catch (err) {
            ShowErrorNotification("Le numéro de téléphone ne semble pas valide.")
            setLoading(false)
        }
    }


    const checkCode = async (code: string) => {
        setLoading(true)
        try {
            const result = await confirmationResult!.confirm(code);
            if (!result.user) {
                throw new Error("error")
            }
            const user = result.user;
            setCounter(0)
            props.onSuccess(user)
        } catch (err) {
            props.onError("Le code est incorrect")
            setLoading(false)
        }
    }


    const onFinish = (values: any) => {
        if (loading) { return }

        if (!confirmationResult) {
            handlePhoneNumber(values.phoneNumber, values.countrycode)
            return
        }
        const code = values.code
        if (code.length == 6) {
            checkCode(code)
        } else {
            ShowErrorNotification("Le code secret est incorrect")
        }
    };

    const handleResend = () => {
        if (counter !== 0) { return }
        setCounter(INITIAL_COUNTER_VALUE)
        sendCode(phoneNumber, true)
    }

    const CountryCodeSelector = (
        <Form.Item initialValue={"FR"} name="countrycode" noStyle>
            <Select defaultValue={"FR"} style={{ flex: 1 }}>
                <Option value="FR">France</Option>
                <Option value="MQ">Martinique</Option>
                <Option value="GP">Guadeloupe</Option>
                <Option value="GF">Guyane</Option>
                <Option value="RE">La Réunion</Option>
                <Option value="PM">Saint-Pierre-et-Miquelon</Option>
                <Option value="YT">Mayotte</Option>
                <Option value="BL">Saint-Barthélemy</Option>
                <Option value="MF">Saint-Martin</Option>
                <Option value="PM">Saint-Pierre-et-Miquelon</Option>
                <Option value="PYF">Polynésie française</Option>
                <Option value="NC">Nouvelle-Calédonie</Option>
            </Select>
        </Form.Item>
    );

    return (
        <div>
            <Form
                name="basic"
                onFinish={onFinish}
                autoComplete="off"
                layout={"vertical"}
                style={{ marginTop: 30, width: 365 }}
            >
                <Form.Item
                    label="Numéro de téléphone portable"
                    name="phoneNumber"
                    rules={[{
                        required: true,
                        message: 'Numero de telephone non valide'
                    }
                    ]}
                >
                    <Input addonBefore={CountryCodeSelector} placeholder={"06 12 34 56 78"} />
                </Form.Item>


                {confirmationResult &&
                    <Form.Item
                        label="Saisissez le code que vous avez reçu par SMS "
                        name="code"
                        style={{
                            marginTop: 30
                        }}
                        rules={[{
                            required: true,
                            message: 'Code non valide',
                        }
                        ]}
                    >
                        <Input placeholder={"123456"} prefix={<LockOutlined style={{ color: "#BFBFBF" }} />} />
                    </Form.Item>
                }
                <Form.Item>
                    <Button loading={loading} type="primary" htmlType="submit" style={{ width: "100%" }}>
                        Envoyer le code de verification
                    </Button>
                </Form.Item>
            </Form>
            {
                confirmationResult && <span>
                    Aucun code reçu ?
                    <span
                        onClick={handleResend}
                        style={{
                            color: counter !== 0 ? "#525c66" : "rgb(6, 102, 235)",
                            marginLeft: 10,
                            marginTop: 20,
                            cursor: "pointer"
                        }}
                    >
                        Renvoyer le code {counter !== 0 ? `dans ${counter} secondes` : ""}
                    </span>
                </span>
            }

            <div ref={element}></div>
        </div >

    )



}


export default PhoneAuth;