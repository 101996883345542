import { httpsCallable } from "firebase/functions"
import { cloudFunction } from "firebaseConfig"

export const SUBSCRIPTION_SEATS_MINIMUM = 4
export const SUBSCRIPTION_BASIC_LIMIT_SEND = 88

export const createPortalSession = async (): Promise<string> => {
    try {
        const fetchSession = httpsCallable(cloudFunction, "createPortalSession");
        const session = await fetchSession() as any
        return session.data.url as string
    } catch (err) {
        console.log("error: ", err)
        throw err
    }
}