import { getToken, getMessaging, isSupported } from "firebase/messaging";
import { addValuesToUser } from "./database";

export const setNotification = async () => {
    const notifSupport = await isSupported();
    if (!notifSupport) {
        return;
    }
    const messaging = getMessaging();
    try {
        const currentToken = await getToken(messaging, { vapidKey: "BIqit0tOikble16WgECN5bMEjBYSTC6DpUVgZrHQvrD5VstZ_nAA3DwXY6DLuUDjBpmEzdCsLd9VqOFhXHkqk18" });
        if (currentToken) {
            await addValuesToUser({ notifToken: currentToken })
        }
    } catch (err) {
        return
    }
};