
import { auth } from "firebaseConfig";
import { signOut } from "firebase/auth";

export function logout(): void {
    try { signOut(auth) } catch (err) { return }
}

export const getUserID = async (): Promise<string> => {
    try {
        return await auth.currentUser?.uid!
    } catch (err) {
        throw err;
    }
}

export const getIDToken = async (): Promise<string> => {
    try {
        const token = await auth.currentUser!.getIdToken()
        return token
    } catch (err) { return "" }
}