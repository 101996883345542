import { Layout, Form, Input, Button, Radio, Space, Modal } from 'antd';
import logo from "Assets/logo.png";
import PhoneAuth from 'Components/Auth/PhoneAuth';
import ShowErrorNotification from 'Components/UI/Notif';
import { useState } from 'react';
import { createNewTeam, signupNewAdminUser } from 'utils/database';
import { TeamOutlined } from '@ant-design/icons';
import { useNavigate } from 'react-router-dom';
import { checkInvitationPending, checkUserExist } from 'utils/server';
import { updateEmail, User } from 'firebase/auth';
import { getWebsiteUrl } from 'utils/dev';

const { Header, Content } = Layout;

export default function AdminSignup() {

    const [loading, setLoading] = useState(false)
    const [isPhoneScreen, setIsPhoneScreen] = useState(false)

    const [userInfos, setUserInfos] = useState<any>({})

    const navigation = useNavigate();

    const checkEmail = (email: string) => {
        //if location is localhost return true
        if ((window.location.hostname === "localhost") || window.location.hostname !== "www.app.getsafetycheck.com") { return true }
        return email.endsWith("@notaires.fr") || email.endsWith("@adsn.fr") || email.endsWith("@adnov.fr") || email.endsWith("@paris.notaires.fr")
    }

    const onFinish = async (values: any) => {
        if (!checkEmail(values.email)) {
            ShowErrorNotification("Vous devez avoir un email @notaire.fr pour vous inscrire")
            return
        }
        setLoading(true)
        try {
            const email = values.email;

            const invitationID = await checkInvitationPending(email)

            if (invitationID !== null) {
                setLoading(false)
                Modal.success({
                    title: "On dirait que vous faites déjà partie d'une équipe",
                    content: "Appuyez sur 'continuer' pour rejoindre l'équipe",
                    icon: <TeamOutlined style={{ color: "#565656" }} />,
                    okText: "continuer",
                    onOk() { navigation(`/invitation?id=${invitationID}`) }
                });
                return
            }
            setLoading(false)
            setUserInfos(values)
            setIsPhoneScreen(true)
        } catch (err) {
            setLoading(false)
            ShowErrorNotification("Contacter l'assistance")
        }
    };

    const onFinishFailed = (errorInfo: any) => {
        console.log('Failed:', errorInfo);
    };

    const handlePhoneSuccess = async (user: User) => {
        try {
            const exist = await checkUserExist(user.phoneNumber!)
            if (exist) {
                navigation("/")
                return
            }
            await updateEmail(user, userInfos.email)
            const teamID = await createNewTeam(user.uid, userInfos.officeName, userInfos.email, userInfos.firstname + " " + userInfos.lastname)
            await signupNewAdminUser(user.uid, userInfos.firstname, userInfos.lastname, userInfos.email, user.phoneNumber!, teamID)
            navigation("/onboarding/email-verification")
        } catch (err) {
            ShowErrorNotification("Veuillez recharger la page")
            return
        }
    }

    return (
        <Layout>
            <Header style={{ backgroundColor: "#fff", position: 'fixed', zIndex: 1, width: '100%', boxShadow: "rgb(225, 228, 232) 0px -1px 0px 0px inset", display: "flex", justifyContent: "space-between" }}>
                <a href={getWebsiteUrl()}>
                    <img src={logo} style={{ height: 20 }} />
                </a>
            </Header>
            <Layout>
                <Content style={{ display: "flex", minHeight: "100vh", alignItems: "center", flexDirection: "column", backgroundColor: "white" }}>

                    <h1 style={{ fontSize: 30, marginTop: 110, textAlign: "center" }}>Essayez gratuitement Safety check</h1>
                    <h5 style={{ fontSize: 15 }}>Pas de carte de crédit nécessaire</h5>

                    <span>Vous avez déjà un compte ? <a href={"/login"}> se connecter à un compte existant </a> </span>

                    {isPhoneScreen ?
                        <PhoneAuth
                            INITIAL_COUNTER_VALUE={180}
                            onError={(message) => { ShowErrorNotification(message) }}
                            onSuccess={handlePhoneSuccess} /> :
                        <Form
                            name="basic"
                            onFinish={onFinish}
                            onFinishFailed={onFinishFailed}
                            autoComplete="off"
                            layout={"vertical"}
                            style={{ marginTop: 50 }}
                        >
                            <Space style={{ display: "flex" }}>
                                <Form.Item
                                    label="Prénom"
                                    name="firstname"
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Ce champ est requis',
                                        },
                                    ]}
                                >
                                    <Input placeholder={"Mark"} />
                                </Form.Item>

                                <Form.Item
                                    label="Nom"
                                    name="lastname"
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Ce champ est requis',
                                        },
                                    ]}
                                >
                                    <Input placeholder={"Dupont"} />
                                </Form.Item>
                            </Space>


                            <Form.Item
                                label="Email professionnel "
                                name="email"
                                rules={[{
                                    required: true,
                                    message: 'Ce champ est requis',
                                    type: "email"
                                }
                                ]}
                            >
                                <Input placeholder={"example@notaires.fr"} />
                            </Form.Item>

                            <Form.Item
                                label="Office notarial"
                                name="officeName"
                                rules={[{
                                    required: true,
                                    message: 'Ce champ est requis',
                                }
                                ]}
                            >
                                <Input placeholder={"SAS Maître dupont"} />
                            </Form.Item>


                            <Form.Item>
                                <Button loading={loading} type="primary" htmlType="submit" style={{ width: "100%" }}>
                                    COMMENCER
                                </Button>
                            </Form.Item>
                        </Form>
                    }

                    <span style={{ color: "#555555", fontSize: 13 }}>En cliquant sur le bouton "Commencer" ci-dessus, vous acceptez les</span>
                    <span style={{ color: "#555555", fontSize: 13 }}>
                        <a href={"https://glory-stay-81a.notion.site/CONDITIONS-GE-NE-RALES-DES-SITES-ET-SERVICES-SAFETY-CHECK-ee1f6ede55004a96a5513f5c79b176d2"}
                            target={"_blank"}
                            style={{ color: "#2463D1" }}>
                            Condition d'utilisations
                        </a>
                        {" "} et {" "}
                        <a target={"_blank"} href={"https://glory-stay-81a.notion.site/Politique-de-Confidentialite-b619e33d9b06469483c31038166af7fe"}
                            style={{ color: "#2463D1" }}>
                            Politique de confidentialité
                        </a>
                    </span>
                </Content>
            </Layout>
        </Layout>
    )
}