import { AuthContext } from "Providers/AuthProvider";
import { useContext } from "react";
import { Navigate } from "react-router-dom"


export default function RequireAuth({ children }: { children: JSX.Element }) {
  const { user } = useContext(AuthContext)

  if (user) return children;

  return <Navigate to="/login" />;
}