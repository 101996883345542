import { useContext, useState, useEffect } from "react"
import { AuthContext } from "Providers/AuthProvider"
import ShowErrorNotification from "Components/UI/Notif";
import { Table } from "antd";
import { onSnapshot, collection, Unsubscribe } from "firebase/firestore";
import { db } from "firebaseConfig";
import { Team, TeamMember } from "types/team.type"


const MembersTable = () => {

    const { getTeam } = useContext(AuthContext)
    const [loading, setLoading] = useState(true)
    const [members, setMembers] = useState<TeamMember[]>([])

    let unsubscribe: Unsubscribe;

    useEffect(() => {
        (async function setup() {
            try {
                const team = await getTeam!()
                fetchTeamMembers(team)
            } catch (err) {
                ShowErrorNotification("")
            }
        })()
        return function cleanup() { unsubscribe() }
    }, [])


    const fetchTeamMembers = (team: Team) => {
        unsubscribe = onSnapshot(collection(db, "TeamsMembers", team.teamID, "members"), (snapshot) => {
            const tempMembers: TeamMember[] = []
            snapshot.forEach((doc) => { tempMembers.push(doc.data() as TeamMember) })
            setMembers(tempMembers)
            setLoading(false)
        }, (error) => {
            ShowErrorNotification("")
        });
    }

    const columns = [
        {
            title: 'Nom',
            dataIndex: 'name',
            key: "name",
            width: '30%',
            render(text: string, record: TeamMember) {
                return {
                    props: {
                        style: { borderWidth: 0, color: record.isLogin ? "#1E1E1E" : "#828B94" }
                    },
                    children: <div>{record.isLogin ? text : "En attente"}</div>
                };
            }

        },
        {
            title: 'Email',
            dataIndex: 'email',
            key: "email",
            width: '39%',
            render(text: string, record: TeamMember) {
                return {
                    props: {
                        style: { borderWidth: 0, color: record.isLogin ? "#1E1E1E" : "#828B94" }
                    },
                    children: <div>{text}</div>
                };
            }
        },
        {
            title: 'Role',
            dataIndex: 'role',
            key: "role",
            width: '30%',
            render(text: string, record: any) {
                return {
                    props: {
                        style: { borderWidth: 0, color: record.isLogin ? "#1E1E1E" : "#828B94" }
                    },
                    children: (
                        (record.isLogin) && <div>{text == "admin" ? "Administrateur" : "Utilisateur"}</div>
                    )
                };
            }

        },
    ]

    return (
        <Table bordered={false} loading={loading} locale={{ "emptyText": "Pas de données" }} columns={columns} dataSource={members} style={{ backgroundColor: "white", marginTop: 60 }} />
    )
}

export default MembersTable;