import { initializeApp } from "firebase/app";
import { getAuth, connectAuthEmulator } from "firebase/auth";
import { getFirestore, connectFirestoreEmulator } from "firebase/firestore";
import { getFunctions, connectFunctionsEmulator } from "firebase/functions";
import { initializeAppCheck, ReCaptchaV3Provider } from "firebase/app-check";
import { getAnalytics } from "firebase/analytics";
import { getStorage, connectStorageEmulator } from "firebase/storage";
import { getCurrentEnv } from "utils/dev";

const CLOUD_FUNCTION_REGION = "europe-west1"

export const config = {
  apiKey: process.env.REACT_APP_API_KEY,
  projectId: process.env.REACT_APP_PROJECT_ID,
  authDomain: process.env.REACT_APP_AUTHDOMAIN,
  storageBucket: process.env.REACT_APP_STORAGEBUCKET,
  messagingSenderId: process.env.REACT_APP_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_APP_ID,
  measurementId: process.env.REACT_APP_MESUREMENT_ID
};

export const firebase = initializeApp(config);
export const auth = getAuth();
export const analystic = getAnalytics()
auth.languageCode = "fr";
export const db = getFirestore();
export const cloudFunction = getFunctions(firebase, CLOUD_FUNCTION_REGION)
export const storage = getStorage();

if (window.location.hostname === "localhost") {
  connectFunctionsEmulator(cloudFunction, "localhost", 5001);
  connectFirestoreEmulator(db, "localhost", 8080)
  connectStorageEmulator(storage, "localhost", 9199)
  connectAuthEmulator(auth, "http://localhost:9099")
} else {
  const token = getCurrentEnv() == "dev" ? "6LeruWIeAAAAANu_3mWg91q41MLLHdMxGjW8PSLL" : "6LecD34cAAAAAET7Z63t-zmW0tF02xqaNVk6_IMA"
  const appCheck = initializeAppCheck(firebase, {
    provider: new ReCaptchaV3Provider(token),
    isTokenAutoRefreshEnabled: true,
  });
}