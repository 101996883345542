import { DashboardLinkStatus } from "types/dashboard.type";
import { Link } from "types/link.type";

export const getLinkStatus = (link: Link): DashboardLinkStatus => {
    if (link.isSet) return "set"
    if (new Date(link.created.seconds * 1000).getTime() + 14 * 24 * 60 * 60 * 1000 < new Date().getTime()) return "expired";
    if ((new Date(link.created.seconds * 1000).getTime() + 3 * 24 * 60 * 60 * 1000 < new Date().getTime()) && !link.userResend) return "resend";
    if ((link.seenDate) && (!link.userResend)) return "resend"
    return "waiting"
};

export const formatTeamName = (teamName: string): string => {
    //format team name to url compatible string
    return encodeURIComponent(teamName.toLowerCase().
        replace(/ /g, "-")
        .replace(/–/g, "-")
        .replace(",", "-")
        .normalize("NFD")
        .replace("&", "et")
        .replace(/-+/g, "-") //remove multiple -
        .replace(/[\u0300-\u036f]/g, ""))
}
