import { config } from "firebaseConfig"

export type ENV_TYPE = "prod" | "dev"

export const getCurrentEnv = (): ENV_TYPE => {
    return (config.projectId == "safetycheck-dev") ? "dev" : "prod"
}

export const getWebsiteUrl = (): string => {
    return getCurrentEnv() == "prod" ? "https://getsafetycheck.fr" : "https://safetycheck-dev.web.app"
}

export const isLocalhost = (): boolean => {
    return window.location.hostname === 'localhost'
}

export const DOWNLOAD_TUTORIAL_LINK = "https://glory-stay-81a.notion.site/Instruction-d-installation-683e5067ebe44b65a20436f26e75eeb4"