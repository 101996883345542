import { analystic } from "firebaseConfig";
import { logEvent, setUserId, setUserProperties } from "firebase/analytics";

export function analystic_logScreen(screenName: string) {
    logEvent(analystic, 'screen_view')
    logEvent(analystic, 'screen_view', { firebase_screen: screenName, firebase_screen_class: screenName });
}

export function analystic_logEvent(name: string, payload = {}) {
    logEvent(analystic, name, payload);
}

export function analystic_setUser(userID: string) {
    setUserId(analystic, userID)
}

export function analystic_setUserProperties(properties: any) {
    setUserProperties(analystic, properties)
}