import { Button } from "antd";
import { FC, useEffect, useState } from "react";
import { ArrowLeftOutlined } from '@ant-design/icons';
import { analystic_logScreen } from "utils/analystic";

interface NewLinkIdentifierProps {
  handleIdentifier: (identifier: string) => void
  back: () => void
}

const NewLinkIdentifier: FC<NewLinkIdentifierProps> = (props) => {

  const [identifier, setIdentifier] = useState<string>("");
  const [loading, setLoading] = useState(false);

  const delay = (ms: number) => new Promise((res) => setTimeout(res, ms));

  useEffect(() => {
    analystic_logScreen("new_link_email_page")
  }, [])

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    handleNext()
  };

  const handleNext = async () => {
    setLoading(true);
    await delay(900);
    setLoading(false);
    props.handleIdentifier(identifier);
  }

  return (
    <div
      style={{
        flex: 1,
        display: "flex",
        alignItems: "center",
        flexDirection: "column",
        height: "100vh",
      }}
    >
      <form
        onSubmit={handleSubmit}
        style={{
          marginTop: "10%",
          flex: 1,
          justifyContent: "space-between",
          padding: 13,
          display: "flex",
          flexDirection: "column",
          width: "100%",
          maxWidth: 555,
          boxSizing: "border-box",
        }}
      >
        <div>
          <div
            style={{
              marginTop: 30,
              display: "flex",
              alignItems: "center",
              justifyContent: "flex-start",
              width: "100%",
            }}
          >
            <ArrowLeftOutlined onClick={props.back} style={{ cursor: "pointer", marginRight: 5 }} />
            <h1
              style={{
                fontSize: 27,
                fontWeight: "normal",
                textAlign: "left",
                color: "#000",
                margin: 0,
                marginLeft: 10
              }}
            >
              Ajoutez un identifiant client
            </h1>
          </div>
          <h5
            style={{
              textAlign: "left",
              color: "#525C66",
              fontSize: 16,
              marginTop: 20,
              fontWeight: "normal",
            }}
          >
            Indiquez un identifiant destinataire comme un email ou un numéro de dossier. Il ne seras pas visible par le destinataire.
          </h5>

          <input
            onChange={(e) => setIdentifier(e.target.value)}
            required
            value={identifier}
            autoFocus
            placeholder={"Identifiant destinataire"}
            style={{
              background: "rgba(32, 51, 69, 0.04)",
              borderRadius: 8,
              borderWidth: 0,
              height: 55,
              marginTop: 70,
              width: "100%",
              boxSizing: "border-box",
              padding: 18,
            }}
          />
        </div>
        <Button
          disabled={!identifier}
          loading={loading}
          onClick={() => handleNext()}
          style={{
            marginBottom: 100,
            width: "100%",
            background: identifier ? "#0666EB" : "#5C9FFB",
            maxWidth: 375,
            padding: "16px 0px",
            borderRadius: 16,
            color: "white",
            alignSelf: "center",
            height: "auto"
          }}
        >
          Suivant
        </Button>
      </form>
    </div>
  );
}

export default NewLinkIdentifier;
