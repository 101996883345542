import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import * as locale from 'dayjs/locale/fr'
dayjs.locale(locale)
dayjs.extend(relativeTime)

export const getPrettyDate = (date: Date): string => {
    return dayjs(date).format("D MMMM YYYY")
}

export const getFormalDate = (date: Date): string => {
    return dayjs(date).format("D/M/YYYY")
}

export const getInboxDate = (date: Date): string => {
    return dayjs(date).format("D MMMM")
}

export const getDateDiff = (date: Date): string => {
    return dayjs().to(dayjs(date))
}

export const daysBetween = (date1: Date, date2: Date): number => {
    try {
        return dayjs(date1).diff(dayjs(date2), 'day')
    } catch (err) {
        throw err
    }
}

