import "./App.css"
import { Routes, Route, BrowserRouter, Navigate } from "react-router-dom";
import RequireAuth from "Routes/PrivateRoute";
import AddClientController from "Screens/Admin/NewLink";
import UserAuthController from "Screens/Auth/Login/Controller";
import MainPage from "Screens/Admin/Dashboard";
import { AuthProvider } from "./Providers/AuthProvider";
import DownloadScreen from "Screens/Download"
import AdminSignup from "Screens/Auth/Signup/AdminSignup";
import InvitationSignup from "Screens/Auth/Signup/InvitationSignup";
import AdminSetttingLayout from "Components/UI/layout";
import TeamSettings from "Screens/Admin/Settings/Members"
import AdminBilling from "Screens/Admin/Settings/Billing";
import SoftwareDownload from "Screens/Auth/Onboarding/SoftwareDownload";
import EmailVerification from "Screens/Auth/Onboarding/EmailVerification";
import OnboardingLayout from "Components/Auth/Onboarding/Layout";
import SettingPreference from "Screens/Admin/Settings/Preference";
import EmailVerified from "Screens/Auth/Onboarding/EmailVerified";

function App() {
  return (
    <AuthProvider>
      <BrowserRouter>
        <Routes>
          
          //Public
          <Route path="/signup" element={<AdminSignup />} />
          <Route path="/login" element={<UserAuthController />} />
          <Route path="/invitation" element={<InvitationSignup />} />
          <Route path="/download" element={<DownloadScreen />} />
          <Route path="/email-verified" element={<EmailVerified />} />

          //Private
          <Route path="/onboarding" element={<RequireAuth><OnboardingLayout /></RequireAuth>}>
            <Route path="email-verification" element={<EmailVerification />} />
            <Route path="download" element={<SoftwareDownload />} />
          </Route>
          
          <Route path="/new" element={<RequireAuth><AddClientController /></RequireAuth>} />
          
          <Route path="/settings" element={<RequireAuth><AdminSetttingLayout /></RequireAuth>}>
            <Route path="team" element={<TeamSettings />} />
            <Route path="billing" element={<AdminBilling />} />
            <Route path="preference" element={<SettingPreference />} />
            <Route index element={<Navigate to="team" />} />
          </Route>

          //default (Home)
          <Route path="*" element={<RequireAuth><MainPage /></RequireAuth>} />
          
        </Routes>
      </BrowserRouter>
    </AuthProvider>
  )
}

export default App;