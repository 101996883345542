export const getResendMail = (mail: string, linkID: string) => {
    return `mailto:${mail}?subject=Rappel%3A%20Demande%20de%20RIB&body=${encodeURIComponent("https://getsafetycheck.fr/o/" + linkID)}%0A`
}

export const getSendMail = (identifier: string, link: string) => {
    let email = ""
    //check if identifier is of type email
    if (identifier.includes("@")) { email = identifier }

    return `mailto:${email}?subject=Demande%20de%20RIB.&body=Madame%2C%20Monsieur%2C%0A%0AAfin%20d%E2%80%99authentifier%20votre%20RIB%2C%20merci%20de%20suivre%20la%20proc%C3%A9dure%20suivante%3A%0A%0AComment%20%C3%A7a%20marche%3F%0A%0A1%20-%20Cliquez%20(ou%20copier-coller)%20ce%20lien%20%3A%20${encodeURIComponent(link)}%0A2%20-%20Chargez%20votre%20RIB.%0A3%20-%20Ajoutez%20le%20code%20secret%20re%C3%A7u%20par%20SMS.%0A4%20-%20Une%20fois%20le%20code%20valid%C3%A9%2C%20votre%20RIB%20sera%20certifi%C3%A9.%0A%0A%0AMerci%20de%20votre%20coop%C3%A9ration.`
}