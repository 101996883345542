import axios from "axios";
import logo from "Assets/logo.png"
import {
    Page,
    Text,
    View,
    Document,
    StyleSheet,
    pdf,
    Image,
    Link as ReportLink
} from "@react-pdf/renderer";
import fileDownload from "js-file-download";
import { getReportPdfUrl, getSignature, getStorageDownloadUrl } from "./storage";
import { getFormalDate, getPrettyDate } from "./dateHelper";
import { getUser } from "./database";
import { DashboardLink } from "types/dashboard.type";
import { getDownloadURL } from "firebase/storage";

const styles = StyleSheet.create({
    logoImg: { width: 200, height: 35 },
    certifContainer: { flexDirection: "row", justifyContent: "space-between" },
    certifText: { fontSize: 9, marginTop: 5 },
    infoContainer: { flexDirection: "row", marginTop: 6, alignItems: "center" },
    infoName: { fontWeight: 600, fontSize: 9, marginRight: 10 },
    infoValue: { fontSize: 9 },
    subtext: { marginTop: 50, fontSize: 13 },
    fileImg: { marginTop: 50 }
});

export default async function downloadReport(link: DashboardLink) {
    try {

        //Chekc if pdf report generated
        const reportPdfUrl = await getReportPdfUrl(link)
        if (reportPdfUrl) {
            const clientFileBlob = await axios.get(reportPdfUrl, { responseType: "blob" }) as any
            fileDownload(clientFileBlob.data, `${link.clientMail}-${link.id}.pdf`);
            return
        }

        const user = await getUser(link.userID)
        const userMail = user.email
        const clientMail = link.clientMail
        const lastUpdate = new Date(link.lastUpdate.seconds * 1000)

        const signatureUrl = await getSignature(link.id, link.userID, link.clientID)

        var fileUrl = await getStorageDownloadUrl(`clientsFiles/${link.userID}/${link.id}/${link.clientID}/clientFile`)

        //download fileUrl
        const clientFileBlob = await axios.get(fileUrl!, { responseType: "blob" }) as any

        const MyDocument = (
            <Document>
                <Page style={{ padding: 30 }}>
                    <View style={styles.certifContainer} fixed >
                        <Image style={styles.logoImg} src={logo} />
                        <View>
                            <Text style={styles.certifText}> Transfert sécurisé </Text>
                            < Text style={styles.certifText} > Généré le {getFormalDate(lastUpdate)} </Text>
                            <ReportLink style={styles.certifText} src="https://www.getsafetycheck.fr" > www.getsafetycheck.fr </ReportLink>
                        </View>
                    </View>

                    <View style={{ marginTop: 5 }
                    }>
                        <View style={styles.infoContainer}>
                            <Text style={styles.infoName}> Expéditeur: </Text>
                            < Text style={styles.infoValue} > {clientMail} </Text>
                        </View>
                        < View style={styles.infoContainer} >
                            <Text style={styles.infoName}> Destinataire: </Text>
                            <Text style={styles.infoValue} > {userMail} </Text>
                        </View>
                        < View style={styles.infoContainer} >
                            <Text style={styles.infoName}> Identifiant de transfert: </Text>
                            <Text style={styles.infoValue} > {link.id} </Text>
                        </View>

                        {clientFileBlob.data.type !== "application/pdf" && <Image style={{ height: 600 }} src={fileUrl!} />}
                        <View style={{ position: "absolute", bottom: 5, left: 0, right: 20, display: "flex", alignItems: "flex-end" }} fixed >
                            <View style={{ display: "flex", alignItems: "center" }}>
                                {signatureUrl && <Image style={{ width: 120 }} src={signatureUrl} />}
                                <Text style={{ textAlign: "center", fontSize: 10, marginTop: 5 }}> {getPrettyDate(lastUpdate)} </Text>
                            </View>
                        </View>
                    </View>
                </Page>
            </Document>
        )

        if (clientFileBlob.data.type !== "application/pdf") {
            const reportBlob = await pdf(MyDocument).toBlob();
            fileDownload(reportBlob, `${clientMail}-${link.id}.pdf`);
            return
        } else {
            fileDownload(clientFileBlob.data, `${clientMail}-${link.id}.pdf`);
            return
        }
    } catch (err) {
        throw err
    }
}