import { FC, useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Layout, Menu, Button, Modal } from "antd";
import {
  InboxOutlined,
  WarningOutlined,
  CheckOutlined,
  ClockCircleOutlined,
  ExclamationCircleOutlined,
  PlusCircleOutlined
} from "@ant-design/icons";
import { isLimitReach } from "utils/database";
import { AuthContext } from "Providers/AuthProvider";
import { DashboardCategory } from "types/dashboard.type";

const DRAWER_WIDTH = 220;

const { Sider } = Layout;

interface SideBarProps {
  categories: DashboardCategory[]
  categoSelected: (category: DashboardCategory) => void
  handleNewSendClick: () => void
}

const SideBar: FC<SideBarProps> = (props) => {

  const navigation = useNavigate();
  const { getTeam } = useContext(AuthContext);
  const [loading, setLoading] = useState(false);

  const handleSend = async () => {
    setLoading(true)
    props.handleNewSendClick()
    /* try {
      const team = await getTeam!()
      const isReach = await isLimitReach(team.teamID)
      if (isReach) {
        setLoading(false)
        Modal.confirm({
          title: "Limite d'envois atteinte",
          content: "Votre equipe a atteint la limite d'envois pour ce mois-ci. Vous pouvez changer de plan pour augmenter la limite de vos envois.",
          icon: <WarningOutlined style={{ color: "#565656" }} />,
          okText: "Changer de formule",
          cancelText: "Annuler",
          onOk() { navigation("/settings/billing") },
        });
        return
      }
      setLoading(false)
      props.handleNewSendClick()
    } catch (err) {
      setLoading(false)
      props.handleNewSendClick()
    } */
  }

  const handleCategoSelect = (catego: DashboardCategory) => {
    props.categoSelected(catego);
  }

  return (
    <Sider trigger={null} width={DRAWER_WIDTH} style={{
      overflow: 'auto',
      height: '100vh',
      position: 'fixed',
      left: 0,
      top: 64
    }}>
      <Menu mode="inline" defaultSelectedKeys={["0"]} style={{ height: '100%', backgroundColor: "#F4F4F4" }}>

        <div style={{ margin: 20 }}>
          <Button
            loading={loading}
            onClick={handleSend}
            style={{
              width: "100%",
              backgroundColor: "#0666eb",
              borderRadius: 2,
              color: "white",
              fontWeight: "600",
              marginTop: 10,
              whiteSpace: "normal",
              height: "auto"
            }}
          >
            GÉNÉRER UNE DEMANDE DE RIB
          </Button>
        </div>
        <h1 style={{
          fontSize: 15,
          marginTop: 30,
          margin: "40px 20px",
          fontWeight: "700"
        }} >
          ACCUEIL
        </h1>
        <Menu.Item onClick={() => handleCategoSelect(props.categories[0])} key="0" icon={<InboxOutlined />}> {props.categories[0].name} </Menu.Item>
        <Menu.Item onClick={() => navigation("/settings")} key="1" icon={<PlusCircleOutlined />}> Ajouter collaborateurs </Menu.Item>

        <h1 style={{
          fontSize: 15,
          marginTop: 30,
          margin: "40px 20px",
          fontWeight: "700",
          textTransform: "uppercase"
        }} >
          Aperçus rapides
        </h1>
        <Menu.Item onClick={() => handleCategoSelect(props.categories[1])} key="2" icon={<CheckOutlined />}> {props.categories[1].name} </Menu.Item>
        <Menu.Item onClick={() => handleCategoSelect(props.categories[2])} key="3" icon={<WarningOutlined />}> {props.categories[2].name} </Menu.Item>
        <Menu.Item onClick={() => handleCategoSelect(props.categories[3])} key="4" icon={<ClockCircleOutlined />}> {props.categories[3].name} </Menu.Item>
        <Menu.Item onClick={() => handleCategoSelect(props.categories[4])} key="5" icon={<ExclamationCircleOutlined />}> {props.categories[4].name} </Menu.Item>
      </Menu>
    </Sider>
  )
}

export default SideBar;





