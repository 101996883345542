import { FC, useEffect, useState } from "react";
import ReactCodeInput from "react-code-input";
import LoadingScreen from "Screens/Loading";

interface UserAuthPhoneCodeProps {
  phoneNumber: string
  loading: Boolean
  checkCode: (code: string) => void
  handleResend: () => void
}

const UserAuthPhoneCode: FC<UserAuthPhoneCodeProps> = (props) => {

  const INITIAL_COUNTER_VALUE = 60;

  const [pinCode, setPinCode] = useState("");

  const [counter, setCounter] = useState(INITIAL_COUNTER_VALUE);

  useEffect(() => {
    counter > 0 && setTimeout(() => setCounter(counter - 1), 1000);
  }, [counter]);

  const handleResend = () => {
    if (counter !== 0) return
    setCounter(INITIAL_COUNTER_VALUE)
    props.handleResend()
  }

  const handlePinChange = (code: string) => {
    setPinCode(code);
    if (code.length == 6) {
      props.checkCode(code);
      setPinCode("");
    }
  };

  if (props.loading) {
    return (
      <div
        style={{
          height: "100vh",
        }}
      >
        <LoadingScreen />
      </div>
    );
  }

  return (
    <div
      style={{
        flex: 1,
        padding: 24,
        display: "flex",
        justifyContent: "center",
        background: "white",
      }}
    >
      <div
        style={{
          width: "100%",
          maxWidth: 590,
          flexDirection: "column",
          marginTop: 100,
        }}
      >
        <h1
          style={{
            fontSize: 33,
            color: "#000",
            fontWeight: "600",
          }}
        >
          Code à 6 chiffres envoyé via {props.phoneNumber}
        </h1>
        <h3
          style={{
            fontSize: 17,
            color: "#525c66",
            fontWeight: "normal",
          }}
        >
          Consultez votre téléphone pour voir si vous avez un message de notre part. Saisissez le code ci-dessous pour continuer.
        </h3>

        <span
          style={{
            fontSize: 16,
            color: "#525c66",
          }}
        >
          Aucun code reçu ?
          <span
            onClick={handleResend}
            style={{
              color: counter == 0 ? "#525c66" : "rgb(6, 102, 235)",
              marginLeft: 10,
              marginTop: 20,
              cursor: "pointer",
            }}
          >
            Renvoyez votre code s'il n'arrive pas d'ici {counter !== 0 ? `dans 00:${counter}` : ""}
          </span>
        </span>

        <div style={{ marginTop: 50 }}>
          <ReactCodeInput
            fields={6}
            inputMode="numeric"
            inputStyle={{
              margin: "4px",
              MozAppearance: "textfield",
              width: 40,
              boxSizing: "border-box",
              borderRadius: 8,
              fontSize: 20,
              fontWeight: "600",
              textAlign: "center",
              height: 55,
              backgroundColor: "rgba(32, 51, 69, 0.04)",
              color: "black",
              border: "1px solid black"
            }}
            name="pinCode"
            onChange={handlePinChange}
            value={pinCode}
          />
        </div>
      </div>
    </div>
  );
}


export default UserAuthPhoneCode;
