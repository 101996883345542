import { Button, Input, Modal } from "antd";
import { FC, useContext, useEffect, useState } from "react";
import { db } from "firebaseConfig";
import { doc, setDoc } from "firebase/firestore";
import Spinner from "Components/UI/Spinner";
import { v4 as uuidv4 } from "uuid";
import { AuthContext } from "Providers/AuthProvider";
import { analystic_logEvent, analystic_logScreen } from "utils/analystic";
import ShowErrorNotification from "Components/UI/Notif";
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { getSendMail } from "utils/mail";
import { useNavigate } from "react-router-dom";
import { formatTeamName } from "utils/link";
import { getCurrentEnv, isLocalhost } from "utils/dev";
import { createNewLink } from "utils/database";


interface NewLinkFinishProps {
  phoneNumber: string
  identifier: string
}

const NewLinkFinishProps: FC<NewLinkFinishProps> = (props) => {

  const { user, getTeam } = useContext(AuthContext);

  const delay = (ms: number) => new Promise((res) => setTimeout(res, ms));

  const navigation = useNavigate()

  const [loading, setLoading] = useState(true);
  const [isModalOpen, setModalOpen] = useState(false)
  const [linkID, setLinkID] = useState("")
  const [teamName, setTeamName] = useState("")
  const [isCopied, setCopied] = useState(false)

  useEffect(() => {
    if (!loading) {
      return;
    }
    createNewSharableLink().then(() => {
      getTeam!().then((team) => {
        setTeamName(formatTeamName(team.teamName));
      }).finally(() => {
        setLoading(false);
      })
    }).catch((err) => {
      ShowErrorNotification("Le mail ou le numero de telephone ne sont pas valide")
    });

    analystic_logScreen("new_link_success_page")
  }, []);

  const showSpamPopup = async () => {
    await delay(300);
    setModalOpen(true)
  }

  const handleHomeButton = () => {
    if (isCopied) {
      navigation("/")
      return
    }
    setModalOpen(true)
  }


  const createNewSharableLink = async () => {
    let uuid = uuidv4();
    uuid = uuid.substring(0, 6);
    setLinkID(uuid)
    try {
      await createNewLink(uuid, props.identifier, props.phoneNumber, user!.uid)
      analystic_logEvent("new_link_send", { linkID: uuid })
      return;
    } catch (err) {
      throw err;
    }
  };

  const getLink = () => {
    if (isLocalhost()) return `localhost:3001/o/${linkID}/${teamName}`
    return getCurrentEnv() == "prod" ? `https://getsafetycheck.fr/o/${linkID}/${teamName}` : `https://client-safetycheck-dev.web.app/o/${linkID}/${teamName}`
  }

  if (loading) {
    return (
      <div
        style={{
          flex: 1,
          display: "flex",
          alignItems: "center",
          flexDirection: "column",
          minHeight: "100vh",
          height: "100%",
          background: "#F7F7F7",
          padding: 13,
        }}
      >
        <div
          style={{
            flex: 1,
            padding: 13,
            display: "flex",
            flexDirection: "column",
            width: "100%",
            maxWidth: 535,
          }}
        >
          <div style={{ display: "flex", justifyContent: "center", marginTop: 80, flexDirection: "column", alignItems: "center" }}>
            <p style={{ fontWeight: "500", fontSize: 20 }}>Création du lien sécurisé...</p>
            <Spinner />
          </div>
        </div>
      </div>
    )
  }



  return (
    <div
      style={{
        flex: 1,
        display: "flex",
        alignItems: "center",
        flexDirection: "column",
        minHeight: "100vh",
        height: "100%",
        background: "#F7F7F7",
        padding: 13,
      }}
    >
      <div
        style={{
          flex: 1,
          padding: 13,
          display: "flex",
          flexDirection: "column",
          width: "100%",
          maxWidth: 535,
        }}
      >


        <div
          style={{
            marginTop: 30,
            display: "flex",
            alignItems: "center",
            justifyContent: "flex-start",
            width: "100%",
          }}
        >
          <h1
            style={{
              fontSize: 34,
              color: "#191c1f",
              marginLeft: 0,
              fontWeight: "normal",
            }}
          >
            Instruction
          </h1>
        </div>



        <div style={{ display: "flex", alignItems: "center", marginTop: 20 }}>
          <div
            style={{
              width: 30,
              height: 30,
              backgroundColor: "#0666EB",
              borderRadius: "50%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <span style={{ color: "white", fontSize: 15 }}>1</span>
          </div>
          <h1
            style={{
              marginLeft: 15,
              fontSize: 20,
              color: "#191c1f",
              fontWeight: "600",
              marginBottom: 0
            }}
          >
            Copiez le lien sécurisé
          </h1>
        </div>
        <div style={{ width: "100%" }}>
          <p
            style={{
              fontSize: 14,
              color: "#75808a",
              marginBottom: 15,
              marginLeft: 5,
              marginTop: 10
            }}
          >
            Copiez le lien sécurisé ci-dessous 👇
          </p>

          <div style={{
            background: "white",
            padding: 16,
            borderRadius: 12,
            display: "flex",
            flexDirection: "column",
            marginTop: 20,
          }}>
            <span
              style={{
                fontSize: 14,
                color: "#75808a",
              }}
            >
              Lien securisé:
            </span>


            <Input.Group style={{ marginTop: 20, marginBottom: 30 }} compact>
              <Input style={{ width: 'calc(100% - 90px)', color: "#0666EB" }} contentEditable={false} defaultValue={getLink()} />
              <CopyToClipboard onCopy={() => setCopied(true)} text={getLink()}>
                <Button type="primary">{isCopied ? "COPIÉ" : "COPIER "} </Button>
              </CopyToClipboard>
            </Input.Group>

          </div>

          <div style={{ display: "flex", alignItems: "center", marginTop: 60 }}>
            <div
              style={{
                width: 30,
                height: 30,
                backgroundColor: "#0666EB",
                borderRadius: "50%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <span style={{ color: "white", fontSize: 15 }}>2</span>
            </div>
            <h1
              style={{
                marginLeft: 15,
                fontSize: 20,
                color: "#191c1f",
                fontWeight: "600",
                marginBottom: 0
              }}
            >
              Intégrer le lien à un email
            </h1>
          </div>


          <div style={{ display: "flex", alignItems: "center", marginTop: 60 }}>
            <div
              style={{
                width: 30,
                height: 30,
                backgroundColor: "#0666EB",
                borderRadius: "50%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <span style={{ color: "white", fontSize: 15 }}>3</span>
            </div>
            <h1
              style={{
                marginLeft: 15,
                fontSize: 20,
                color: "#191c1f",
                fontWeight: "600",
                marginBottom: 0
              }}
            >
              Envoyer l'e-mail au client
            </h1>
          </div>
          <p
            style={{
              fontSize: 14,
              color: "#75808a",
              marginBottom: 15,
              marginLeft: 5,
              marginTop: 10
            }}
          >
            ou cliquez <a href={getSendMail(props.identifier, getLink())} onClick={() => setCopied(true)}>ici</a> pour utiliser un mail pré-rempli.
          </p>

        </div>

      </div>

      <Button
        onClick={handleHomeButton}
        size={"middle"}
        style={{
          width: 180,
          background: "#0666EB",
          borderRadius: 9,
          color: "white",
          alignSelf: "center",
          bottom: 60,
          position: "fixed",
          left: "50%",
          transform: "translateX(-50%)",
          height: 50,
          textAlign: "center",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          fontWeight: "bold"
        }}
      >Terminer</Button>

      <Modal
        onCancel={() => setModalOpen(false)}
        title="Avertissement"
        closable={false}
        visible={isModalOpen}
        footer={[
          <div>
            <Button type={"default"} onClick={() => setModalOpen(false)}>
              Je n'ai pas envoyé le lien
            </Button>
            <Button type={"primary"} onClick={() => navigation("/")}>
              J'ai envoyé le lien au client
            </Button>
          </div>
        ]}
      >
        <p>Vous n'avez pas copié le lien. Si vous ne copiez pas le lien et ne l'envoyez pas au client, il ne pourra pas envoyer son RIB.</p>
      </Modal>
    </div>
  );
}


export default NewLinkFinishProps;
