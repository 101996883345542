import { Result } from "antd";

export default function EmailVerified() {

    return (
        <div style={{ marginTop: 100 }}>
            <div style={{ display: "flex", justifyContent: "center" }}>
                <Result
                    status="success"
                    style={{ maxWidth: 600 }}
                    title="E-mail vérifié avec succès"
                    subTitle={`Veuillez revenir à votre fenêtre précédente et cliquer sur le bouton "J'ai validé mon email" pour finaliser votre inscription.`}
                />,
            </div>
        </div>
    );
}
