import { db } from "firebaseConfig";
import { doc, getDoc, setDoc, addDoc, collection, getDocs, deleteDoc, serverTimestamp } from "firebase/firestore";
import { v4 } from "uuid";
import { SUBSCRIPTION_BASIC_LIMIT_SEND } from "./subscription";
import { Invitation, Team, TeamMember, TeamRole } from "types/team.type";
import { User } from "types/user.type";
import { getUserID } from "./auth";


export const getUser = async (userID: string): Promise<User> => {
    try {
        const docRef = doc(db, "Users", userID);
        const docSnap = await getDoc(docRef);
        return docSnap.data() as User
    } catch (err) {
        throw err
    }
}

export const createNewLink = async (id: string, identifier: string, phone: string, userID: string): Promise<void> => {
    try {
        await setDoc(doc(db, "Links", id), {
            clientMail: identifier,
            clientPhoneNumber: phone,
            userID: userID,
            linkID: id,
            isSet: false
        });
    } catch (err) {
        throw err
    }
}

export const addValuesToUser = async (values: object): Promise<void> => {
    try {
        const userID = await getUserID()
        await setDoc(doc(db, "Users", userID), {
            ...values
        }, { merge: true });
    } catch (err) {
        throw err;
    }
}

export const setMailResend = async (linkID: string): Promise<void> => {
    try {
        await setDoc(doc(db, "Links", linkID), { userResend: true }, { merge: true });
        return
    } catch (err) {
        throw err
    }
}

export const pdfErrorRequest = async (linkID: string, userID: string): Promise<void> => {
    try {
        await setDoc(doc(db, "PDFRequest", linkID), {
            linkID: linkID,
            userID: userID,
            sent: false,
            lastUpdate: serverTimestamp()
        });
        return
    }
    catch { return }
}

export const signupNewAdminUser = async (userID: string, firstName: string, lastName: string, email: string, phoneNumber: string, teamID: string) => {
    try {
        await setDoc(doc(db, "Users", userID), {
            firstName,
            lastName,
            fullName: firstName + " " + lastName,
            email: email.toLowerCase(),
            phoneNumber,
            userID,
            createdDate: serverTimestamp(),
            isTeamVerified: false,
            teamID
        });
        return
    } catch (err) {
        throw err
    }
}


export const createNewTeam = async (userID: string, teamName: string, email: string, fullName: string): Promise<string> => {

    const teamID = v4()

    try {
        await setDoc(doc(db, "Teams", teamID), {
            teamName,
            teamID,
            adminID: userID,
            plan: "basic",
            status: "trial",
            linkCount: 0,
            activeSeats: 1
        });
        return teamID
    } catch (err) {
        throw err
    }
}


export const addMultipleTeamMembers = async (membersMail: string[], teamID: string) => {
    const tempID = v4()
    try {
        await setDoc(doc(db, "TempAddedUser", tempID), {
            teamID,
            emails: membersMail
        })
    } catch (err) {
        throw err
    }
}

export const getTeam = async (teamID: string): Promise<Team> => {
    try {
        const docSnap = await getDoc(doc(db, "Teams", teamID));
        return docSnap.data() as Team;
    } catch (err) {
        throw err
    }
}

export const getTeamForUser = async (userID: string): Promise<Team> => {
    try {
        //get user team
        const user = await getUser(userID)
        return await getTeam(user.teamID)
    } catch (err) {
        throw err
    }
}

export const getTeamMembers = async (teamID: string): Promise<TeamMember[]> => {
    try {
        const querySnapshot = await getDocs(collection(db, "TeamsMembers", teamID, "members"));
        const members: TeamMember[] = []
        querySnapshot.forEach((doc) => { members.push(doc.data() as TeamMember) })
        return members
    } catch (err) {
        throw err
    }
}

export const deleteLink = async (linkID: string): Promise<void> => {
    try {
        await deleteDoc(doc(db, "ClientLink", linkID));
        await deleteDoc(doc(db, "Links", linkID));
        return
    } catch (err) {
        throw err
    }
}

export const getInvitationInfos = async (invitationID: string): Promise<Invitation> => {
    try {
        const docSnap = await getDoc(doc(db, "Invitations", invitationID));
        if (docSnap.exists()) { return docSnap.data() as Invitation }
        else { throw new Error("error") }
    } catch (err) {
        throw err
    }
}


export const isLimitReach = async (teamID: string) => {
    try {
        const team = await getTeam(teamID)
        const { linkCount, plan } = team
        if (linkCount == undefined || plan == undefined) return false
        return (plan == "basic" && linkCount >= SUBSCRIPTION_BASIC_LIMIT_SEND)
    } catch (err) {
        return false
    }
}

/* export const haveAddedTeam = async (teamID: string) => {
    const docSnap = await getDoc(doc(db, "Team", invitationID));
} */

export const setUserUseSoftware = async (userID: string, use: boolean) => {
    try {
        await setDoc(doc(db, "Users", userID), {
            useSoftware: use
        }, { merge: true });
    } catch (err) {
        throw err
    }
}


export const isTeamPopupViewed = async (userID: string): Promise<boolean> => {
    try {

        const docRef = doc(db, "Users", userID);
        const docSnap = await getDoc(docRef);
        if (!docSnap.exists()) return true
        return docSnap.data()["teamPopupViewed"]
    } catch (err) {
        return true;
    }
}

export const setTeamPopupView = async (userID: string) => {
    try {
        await setDoc(doc(db, "Users", userID), {
            teamPopupViewed: true
        }, { merge: true });
        return
    } catch (err) {
        throw err
    }
}

