import { useState } from "react";
import NewLinkPhone from "Components/Admin/NewLink/Phone";
import NewLinkIdentifier from "Components/Admin/NewLink/Identifier";
import NewLinkFinish from "Components/Admin/NewLink/Finish";

export default function NewLink() {
  const [index, setIndex] = useState(0);
  const [identifier, setIdentifier] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");

  const handlePhoneNumber = (phoneNumber: string) => {
    setPhoneNumber(phoneNumber);
    next();
  };

  const handleIdentifier = (identifier: string) => {
    setIdentifier(identifier);
    next();
  };

  const back = () => { setIndex(index - 1) }
  const next = () => { setIndex(index + 1) }

  return (
    <div>
      {index == 0 && <NewLinkPhone phoneNumber={phoneNumber} handlePhoneNumber={handlePhoneNumber} />}
      {index == 1 && <NewLinkIdentifier handleIdentifier={handleIdentifier} back={back} />}
      {index == 2 && <NewLinkFinish identifier={identifier} phoneNumber={phoneNumber} />}
    </div>
  );
}
