import { Outlet, useLocation, useNavigate } from "react-router-dom";
import { Layout, Menu } from "antd";
import NavBar from "Components/UI/NavBar";
import { TeamOutlined, CreditCardOutlined, ArrowLeftOutlined, SettingOutlined } from "@ant-design/icons"
const { Content, Sider } = Layout;

const DRAWER_WIDTH = 220;

export default function AdminSetttingLayout() {

    return (
        <Layout>
            <NavBar selected="settings" />
            <Layout>
                <SideBar />
                <Content style={{ backgroundColor: "white" }}>
                    <div style={{ flex: 1, marginLeft: DRAWER_WIDTH, marginTop: 60 }}>
                        <Outlet />
                    </div>
                </Content>
            </Layout >
        </Layout >
    )
}


function SideBar() {

    const navigation = useNavigate()
    const location = useLocation()

    const items = [
        { key: '1', path: '/settings/team' },
        /* { key: '2', path: '/settings/billing' }, */
        { key: '3', path: '/settings/preference' }
    ]

    const showPreniumPopup = async () => {
        /*  const portalURL = await createPortalSession()
         window.location.href = portalURL */
    }

    const getCurrentSelect = () => {
        try {
            return (items.find(_item => location.pathname.startsWith(_item.path))!.key)
        } catch (err) { return items[0].key }
    }

    return (
        <Sider trigger={null} width={DRAWER_WIDTH} style={{
            overflow: 'auto',
            height: '100vh',
            position: 'fixed',
            left: 0,
            top: 64
        }}>
            <Menu mode="inline" defaultSelectedKeys={[getCurrentSelect()]} style={{ height: '100%', backgroundColor: "#F4F4F4" }}>
                <Menu.Item key="0" onClick={() => navigation("/")} icon={<ArrowLeftOutlined />}>Retour</Menu.Item>
                <h1 style={{
                    fontSize: 15,
                    marginTop: 30,
                    margin: "40px 20px",
                    fontWeight: "700",
                    textTransform: "uppercase"
                }} >
                    Espace de travail
                </h1>
                <Menu.Item key="1" onClick={() => navigation("team")} icon={<TeamOutlined />}>Mon équipe</Menu.Item>
{/*                 <Menu.Item key="2" onClick={() => navigation("billing")} icon={<CreditCardOutlined />}>Facture & paiment</Menu.Item>
 */}                <Menu.Item key="3" onClick={() => navigation("preference")} icon={<SettingOutlined />}>Préférences</Menu.Item>
            </Menu>
        </Sider>
    )
}